import Grid from '@mui/material/Grid'
import { Permission } from '@generatedTypes/graphql'
import { type AttachedFile } from '@interfaces/Upload'
import User from '@models/User'
import AclEntity from '@models/AclEntity'
import Typography from '@mui/material/Typography'
import FileItem from '@components/atoms/FileItem'

interface Props {
  files?: AttachedFile[]
  fileNameTruncateLength?: number
  filesAttachedLabel?: string
  noFilesAttachedLabel?: string
  user: User
  onFileClick?: (documentPath: string) => void
  onRemoveFile?: (key: string) => void
}

const FilesList = ({
  files,
  user,
  fileNameTruncateLength = 35,
  filesAttachedLabel = 'Files attached',
  noFilesAttachedLabel = 'No files attached',
  onFileClick,
  onRemoveFile,
}: Props): JSX.Element => {
  const fileCount = files?.length ?? 0

  return (
    <Grid container aria-label="Task attachment list">
      <Grid container item spacing={2} my={1}>
        <Grid item>
          <Typography
            variant="subtitle1"
            data-testid="filelist-files-attached-lbl"
          >
            {fileCount > 0 ? filesAttachedLabel : noFilesAttachedLabel}
          </Typography>
        </Grid>
      </Grid>
      {files?.map((file) => {
        const aclEntity = new AclEntity(file.acl)
        const canDelete = aclEntity.allows(
          user.aclIdentities,
          Permission.DocumentsDelete,
        )
        return (
          <FileItem
            key={file.key}
            truncateLength={fileNameTruncateLength}
            name={file.name}
            onClick={onFileClick ? () => onFileClick(file.fullPath) : undefined}
            onRemove={
              onRemoveFile && canDelete
                ? () => onRemoveFile(file.key)
                : undefined
            }
          />
        )
      })}
    </Grid>
  )
}

export default FilesList
