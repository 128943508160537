import { useParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'
import { routes } from '@/routes'
import Link from '@mui/material/Link'
import { Alert, Box, Button, Container } from '@mui/material'

const CheckoutFinalized = (): JSX.Element => {
  const { SESSION_ID } = useParams()

  return (
    <Container>
      {SESSION_ID !== undefined ? (
        <Alert severity="success">
          <Typography variant="h2">Checkout Success</Typography>
          <Typography variant="body1" my={2}>
            You have successfully completed the checkout process. You can now
            view and manage your legal plan from the{' '}
            <Link
              href={routes.userSubscriptionStatus.path}
              sx={{ fontSize: '16px', cursor: 'pointer' }}
            >
              My Legal Plan
            </Link>{' '}
            page linked under your profile.
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              href={routes.legalServiceSelection.path}
              variant="contained"
            >
              Request Legal Services
            </Button>
          </Box>
        </Alert>
      ) : (
        <Alert severity="error">
          <Typography variant="h2">Checkout Failed</Typography>
          <Typography variant="body1" my={2}>
            The checkout process was not completed. Please follow the link to{' '}
            <Link
              href={routes.checkoutManageProducts.path}
              sx={{ fontSize: '16px', cursor: 'pointer' }}
            >{`products`}</Link>{' '}
            to view the product listing.
          </Typography>
        </Alert>
      )}
    </Container>
  )
}

export default CheckoutFinalized
