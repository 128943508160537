/* eslint-disable @typescript-eslint/no-misused-promises */
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ItemBoundingBox from '@components/atoms/ItemBoundingBox'
import { type SubmitHandler, useForm } from 'react-hook-form'
import EventDateTime from './EventDateTime'
import { isPast, isAfter, addMinutes, roundToNearestMinutes } from 'date-fns'
import { UsTimezonesType } from '@/utils/dateUtils'
import log from 'loglevel'

interface DataProps {
  eventKey?: string
  eventName?: string
  eventDescription?: string
  startTime?: Date
  endTime?: Date
  userTimeZone?: UsTimezonesType
}

type Props = DataProps & {
  namePlaceholder?: string
  descriptionPlaceholder?: string
  textButton?: string
  textCancelButton?: string
  isSubmitting: boolean
  serverError?: string[]
  onHandleChange?: (event) => void
  onChangeEventStartTime?: (value?: Date) => void
  onChangeEventEndTime?: (value?: Date) => void
  onClickButton: (data: DataProps) => void
  onClickCancelButton: (value?: string) => void
}

const offSetEndTime = (startTime: Date, offSetMinutes = 30): Date => {
  return addMinutes(startTime, offSetMinutes)
}

const EventForm = ({
  eventKey,
  eventName = '',
  eventDescription = '',
  startTime = roundToNearestMinutes(new Date(), {
    nearestTo: 30,
    roundingMethod: 'ceil',
  }),
  endTime = offSetEndTime(startTime),
  userTimeZone,
  namePlaceholder = 'Enter title',
  descriptionPlaceholder = 'Add notes for client',
  textButton = 'Save',
  textCancelButton = 'Cancel',
  serverError,
  isSubmitting = false,
  onHandleChange,
  onChangeEventStartTime,
  onChangeEventEndTime,
  onClickButton,
  onClickCancelButton,
}: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors: validationErrors },
  } = useForm<DataProps>()

  const fields = {
    eventKey: register('eventKey', { value: eventKey }),
    eventName: register('eventName', {
      value: eventName,
      required: { value: true, message: 'Event name is required' },
      pattern: {
        value: /\w+\b/i,
        message: 'Please enter a valid value',
      },
      maxLength: 200,
    }),
    eventDescription: register('eventDescription', {
      value: eventDescription,
      pattern: {
        value: /\w+\b/i,
        message: 'Please enter a valid value',
      },
      maxLength: 1000,
    }),
    startTime: register('startTime', {
      value: startTime,
    }),
    endTime: register('endTime', {
      value: endTime,
    }),
  }

  const startTimeValue = watch('startTime', startTime)
  const endTimeValue = watch('endTime', endTime)

  const onSubmit: SubmitHandler<DataProps> = (data) => {
    let formValid = true

    if (startTimeValue !== undefined && isPast(new Date(startTimeValue))) {
      setError('startTime', { message: 'Start time cannot be set in the past' })
      formValid = false
    }
    if (
      startTimeValue !== undefined &&
      endTimeValue !== undefined &&
      isAfter(new Date(startTimeValue), new Date(endTimeValue))
    ) {
      setError('startTime', {
        message: 'Start time should be set earlier than end time',
      })
      formValid = false
    }
    if (formValid) onClickButton(data)
  }
  return (
    <form
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData)
      })}
    >
      <ItemBoundingBox variant="outlineBox">
        <Grid container justifyContent="space-between">
          <Grid size={{ xs: 8, sm: 6 }}>
            <TextField
              fullWidth
              id="eventName"
              type="text"
              {...fields.eventName}
              error={!(validationErrors?.eventName === undefined)}
              helperText={validationErrors?.eventName?.message}
              onChange={onHandleChange}
              placeholder={namePlaceholder}
              size="small"
              variant="standard"
              aria-placeholder={namePlaceholder}
              role="textbox"
              data-testid="create-event-input-name"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12 }} my={2}>
            <TextField
              fullWidth
              multiline
              id="eventDescription"
              type="text"
              {...fields.eventDescription}
              error={!(validationErrors?.eventDescription === undefined)}
              helperText={validationErrors?.eventDescription?.message}
              placeholder={descriptionPlaceholder}
              onChange={onHandleChange}
              rows={2}
              variant="standard"
              data-testid="create-event-input-description"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          <EventDateTime
            startTime={startTimeValue}
            endTime={endTimeValue}
            timeZone={userTimeZone}
            onChangeStartTime={(value) => {
              setValue('startTime', value)
              setValue('endTime', offSetEndTime(value))
              onChangeEventStartTime?.(value)
            }}
            onChangeEndTime={(value) => {
              setValue('endTime', value)
              onChangeEventEndTime?.(value)
            }}
          />
          {validationErrors?.startTime !== undefined && (
            <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
              {validationErrors?.startTime?.message}
            </Typography>
          )}
          <Grid
            container
            direction={'row'}
            data-testid="create-event-server-error"
          >
            {serverError?.map((e) => (
              <Grid>
                <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                  {e}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {textButton !== undefined && textCancelButton !== undefined ? (
            <Grid container justifyContent="flex-end">
              <Grid marginX={1}>
                <Button
                  disableElevation
                  variant="text"
                  color="secondary"
                  data-testid="create-event-cancel-button"
                  onClick={() => {
                    onClickCancelButton(eventKey)
                  }}
                  sx={{ textTransform: 'none' }}
                >
                  {textCancelButton}
                </Button>
              </Grid>
              <Grid marginX={1}>
                <Button
                  loading={isSubmitting}
                  disableElevation
                  variant="contained"
                  color="primary"
                  data-testid="create-event-text-button"
                  type="submit"
                >
                  {textButton}
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </ItemBoundingBox>
    </form>
  )
}

export default EventForm
