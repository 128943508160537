import { UpdateBaseUserMutation } from '@generatedTypes/graphql'
import { gql } from '@generatedTypes/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'

const updateBaseUserMutation = gql(/* GraphQL */ `
  mutation updateBaseUser(
    $baseUserKey: Key!
    $formType: JSONFormType!
    $data: [JSON!]!
  ) {
    baseUserUpdate(
      baseUserKey: $baseUserKey
      formType: $formType
      data: $data
    ) {
      ... on BaseUserMutationResponse {
        code
        success
        message
        user {
          ... on BaseUser {
            email
            firstName
            gender
            key
            lastName
            middleName
            phone
          }
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

interface BaseUserHook {
  updateBaseUser: (
    mutationOpts:
      | MutationFunctionOptions<
          UpdateBaseUserMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<UpdateBaseUserMutation>>
  baseUserLoading: boolean
  baseUserError: any
}

const useBaseUser = (): BaseUserHook => {
  const [updateBaseUser, { loading: baseUserLoading, error: baseUserError }] =
    useMutation<UpdateBaseUserMutation>(updateBaseUserMutation)

  return {
    updateBaseUser,
    baseUserLoading,
    baseUserError,
  }
}

export default useBaseUser
