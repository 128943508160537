import React from 'react'
import Grid from '@mui/material/Grid'
import ListItemBoxHeading from '@components/molecules/ListItemBoxHeading'
import ItemBoundingBox from '@components/atoms/ItemBoundingBox'
import { formatCurrency } from '@utils/StringFormatter'
import Chip from '@mui/material/Chip'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { TaskChargeType } from '@generatedTypes/graphql'

interface Props {
  taskName: string
  chargeType?: TaskChargeType
  chargeReason: string
  chargeAmount: number
}

const ChargeforTaskInfo = ({
  taskName,
  chargeType,
  chargeReason,
  chargeAmount,
}: Props): JSX.Element => {
  return (
    <ItemBoundingBox variant="outlineBox">
      <ListItemBoxHeading
        title={taskName}
        disableMenu={true}
        midContent={
          <Chip
            icon={<ErrorOutlineIcon />}
            color="warning"
            label={
              chargeType === TaskChargeType.OutOfPlan
                ? 'Out of Plan'
                : chargeType === TaskChargeType.InPlanFlat
                  ? 'In plan flat fee'
                  : ''
            }
            variant="outlined"
            data-testid="task-form-outofplan-indicator"
          />
        }
        subtitle={formatCurrency(chargeAmount)}
      />
      <hr style={{ marginTop: 16, marginBottom: 16 }} />
      <Grid container item xs={12} sm={12}>
        {chargeReason}
      </Grid>
    </ItemBoundingBox>
  )
}

export default ChargeforTaskInfo
