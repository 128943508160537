import { type Scalars } from '@generatedTypes/graphql'
import { decodeBase64, encodeToBase64 } from './StringFormatter'

export const decodeKey = (key: string): { entity: string; id: string } => {
  const decodedKey = decodeBase64(key)
  const [entity, id] = decodedKey.split('/')
  return { entity, id }
}

export const encodeKey = (entity: string, uid: string): Scalars['Key'] => {
  const encodeKey = encodeToBase64(`${entity}/${uid}`)
  return encodeKey
}
