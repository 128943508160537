/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import log from 'loglevel'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ItemBoundingBox from '@components/atoms/ItemBoundingBox'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'

interface DataProps {
  ChargeReason?: string
  ChargeAmount?: string
}

type Props = DataProps & {
  reasonPlaceholder?: string
  amountPlaceholder?: string
  serverError?: string[]
  isSubmitting?: boolean
  onClickButton: (data: DataProps) => void
  onClickCancelButton: () => void
}

const ChargeforTaskForm = ({
  reasonPlaceholder = 'Explain the reason for the additional charges',
  amountPlaceholder = 'Additional amount',
  serverError,
  isSubmitting = false,
  onClickButton,
  onClickCancelButton,
}: Props): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors: validationErrors },
  } = useForm<DataProps>()

  const fields = {
    chargeReason: register('ChargeReason', {
      required: { value: true, message: 'Reason is required' },
      pattern: {
        value: /\w+\b/i,
        message: 'Please enter a valid value',
      },
      maxLength: 200,
    }),
    chargeAmount: register('ChargeAmount', {
      required: { value: true, message: 'Amount is required' },
      pattern: {
        value: /^\d+(\.\d+)?$/,
        message: 'Please use digits and decimal points only',
      },
    }),
  }

  const onSubmit: SubmitHandler<DataProps> = (data) => {
    log.warn('ChargeforTaskForm')
    onClickButton(data)
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ItemBoundingBox variant="outlineBox">
        <Grid container justifyContent="space-between">
          <Grid size={{ xs: 12, sm: 12 }} my={2}>
            <TextField
              fullWidth
              multiline
              id="chargeReason"
              type="text"
              {...fields.chargeReason}
              error={!(validationErrors?.ChargeReason === undefined)}
              helperText={validationErrors?.ChargeReason?.message}
              placeholder={reasonPlaceholder}
              size="small"
              rows={2}
              variant="standard"
              data-testid="task-form-charge-reason"
            />
          </Grid>
          <Grid container size={{ xs: 12, sm: 12 }} my={2} direction={'row'}>
            <Grid size={{ xs: 8, sm: 8 }}>
              <TextField
                id="chargeAmount"
                type="text"
                InputProps={{
                  inputMode: 'decimal',
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                {...fields.chargeAmount}
                error={!(validationErrors?.ChargeAmount === undefined)}
                helperText={validationErrors?.ChargeAmount?.message}
                placeholder={amountPlaceholder}
                variant="standard"
                data-testid="task-form-charge-amount"
              />
            </Grid>
            <Grid size={{ xs: 4 }} display={'flex'} justifyContent={'flex-end'}>
              <Button
                loading={isSubmitting}
                loadingPosition="start"
                aria-label="Save"
                color="primary"
                type="submit"
                variant="text"
                data-testid="charge-form-save-button"
                startIcon={
                  <CheckCircleOutlineIcon
                    sx={{
                      color: 'var(--mui-palette-primary-main)',
                      height: '24px',
                      width: '24px',
                    }}
                  />
                }
              ></Button>
              {!isSubmitting && (
                <IconButton
                  aria-label="Cancel"
                  onClick={onClickCancelButton}
                  color="secondary"
                  data-testid="charge-form-cancel-button"
                >
                  <CancelOutlinedIcon
                    sx={{
                      color: 'var(--mui-palette-warning-light)',
                      height: '24px',
                      width: '24px',
                    }}
                  />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction={'row'}>
          {serverError?.map((e) => (
            <Grid>
              <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                {e}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </ItemBoundingBox>
    </form>
  )
}

export default ChargeforTaskForm
