import {
  AcceptLegalMatterMutation,
  AcceptReferredLegalMatterMutation,
  AssignLegalMatterMutation,
  CancelLegalMatterMutation,
  ClaimLegalMatterMutation,
  CloseLegalMatterMutation,
  CreateLegalMatterMutation,
  LegalMatterShareRejectMutation,
  LegalMatterStatusFragmentDoc,
  RejectLegalMatterMutation,
  LegalMatterReferralRejectMutation,
  ShareLegalMatterMutation,
  WithdrawLegalMatterMutation,
  LegalMatterReferMutation,
  LegalMatterPreviewModeMutation,
} from '@generatedTypes/graphql'
import { gql } from '@generatedTypes/gql'
import {
  ApolloCache,
  DefaultContext,
  FetchResult,
  MutationFunctionOptions,
  OperationVariables,
  useMutation,
} from '@apollo/client'

const createLegalMatterMutation = gql(/* GraphQL */ `
  mutation createLegalMatter(
    $subscriberRef: Key!
    $kindRef: Key!
    $intakeDataRef: Key
  ) {
    legalMatterCreate(
      subscriberRef: $subscriberRef
      kindRef: $kindRef
      intakeDataRef: $intakeDataRef
    ) {
      ... on LegalMatterMutationResponse {
        code
        message
        success
        legalMatter {
          acceptedTimestamp
          key
          kind {
            name
            key
            description
          }
          status
          subscriber {
            key
          }
        }
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          code
          fieldErrors {
            errors {
              code
              message
            }
            name
          }
        }
      }
    }
  }
`)

const acceptLegalMatterMutation = gql(/* GraphQL */ `
  mutation acceptLegalMatter($legalMatterKey: Key!) {
    legalMatterAccept(legalMatterKey: $legalMatterKey) {
      ... on LegalMatterMutationResponse {
        code
        legalMatter {
          ...LegalMatterStatus
        }
        message
        success
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          code
          fieldErrors {
            errors {
              message
              code
            }
            name
          }
        }
      }
    }
  }
`)

const rejectLegalMatterMutation = gql(/* GraphQL */ `
  mutation rejectLegalMatter($legalMatterKey: Key!, $rejectionReason: String!) {
    legalMatterReject(
      legalMatterKey: $legalMatterKey
      rejectionReason: $rejectionReason
    ) {
      ... on LegalMatterMutationResponse {
        code
        legalMatter {
          ...LegalMatterStatus
        }
        message
        success
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          code
          fieldErrors {
            errors {
              message
              code
            }
            name
          }
        }
      }
    }
  }
`)

const assignLegalMatterMutation = gql(/* GraphQL */ `
  mutation assignLegalMatter($legalMatterKey: Key!, $lawyerKey: Key!) {
    legalMatterAssign(legalMatterKey: $legalMatterKey, lawyerKey: $lawyerKey) {
      ... on LegalMatterMutationResponse {
        code
        success
        message
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const claimLegalMatterMutation = gql(/* GraphQL */ `
  mutation claimLegalMatter($legalMatterKey: Key!) {
    legalMatterClaim(legalMatterKey: $legalMatterKey) {
      ... on LegalMatterMutationResponse {
        code
        success
        message
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        success
        message
      }
    }
  }
`)

const cancelLegalMatterMutation = gql(`
  mutation cancelLegalMatter($legalMatterKey: Key!) {
    legalMatterCancel(legalMatterKey: $legalMatterKey) {
      ... on LegalMatterMutationResponse {
        code
        message
        success
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        message
        success
      }
    }
  }
`)

const closeLegalMatterMutation = gql(/* GraphQL */ `
  mutation closeLegalMatter($legalMatterKey: Key!) {
    legalMatterClose(legalMatterKey: $legalMatterKey) {
      ... on LegalMatterMutationResponse {
        code
        message
        success
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        message
        success
      }
    }
  }
`)

const withdrawLegalMatterMutation = gql(/* GraphQL */ `
  mutation withdrawLegalMatter(
    $legalMatterKey: Key!
    $withdrawReason: String!
  ) {
    legalMatterWithdraw(
      legalMatterKey: $legalMatterKey
      withdrawReason: $withdrawReason
    ) {
      ... on LegalMatterMutationResponse {
        code
        message
        success
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        message
        success
      }
    }
  }
`)

const shareLegalMatterMutation = gql(`
  mutation shareLegalMatter($input: ShareMutationInput!) {
    legalMatterShare(input: $input) {
      ... on LegalMatterMutationResponse {
        code
        success
        message
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          fieldErrors {
            name
            errors {
              message
              code
            }
          }
        }
      }
    }
  }
`)

const acceptReferredLegalMatterMutation = gql(/* GraphQL */ `
  mutation acceptReferredLegalMatter($legalMatterKey: Key!) {
    legalMatterReferralAccept(legalMatterKey: $legalMatterKey) {
      ... on LegalMatterMutationResponse {
        code
        success
        message
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const legalMatterReferralRejectMutation = gql(/* GraphQL */ `
  mutation LegalMatterReferralReject(
    $legalMatterKey: Key!
    $labelKey: Key!
    $additionalNotes: String
  ) {
    legalMatterReferralReject(
      legalMatterKey: $legalMatterKey
      labelKey: $labelKey
      additionalNotes: $additionalNotes
    ) {
      ... on LegalMatterMutationResponse {
        code
        success
        message
        legalMatter {
          ...LegalMatterStatus
        }
      }
      ... on MutationError {
        code
        success
        message
        userErrors {
          code
          fieldErrors {
            name
            errors {
              code
              message
            }
          }
        }
      }
    }
  }
`)

const legalMatterShareRejectMutation = gql(`
  mutation LegalMatterShareReject($legalMatterKey: Key!, $userMessage: String!) {
    legalMatterShareReject(legalMatterKey: $legalMatterKey, userMessage: $userMessage) {
      ... on LegalMatterMutationResponse {
        code
        success
        message
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          fieldErrors {
            name
            errors {
              message
              code
            }
          }
        }
      }
    }
  }
`)

const legalMatterReferMutation = gql(`
mutation LegalMatterRefer($legalMatterKey: Key!, $labelRef: Key!, $userType: UserType!, $additionalNotes: String) {
  legalMatterRefer(legalMatterKey: $legalMatterKey, labelRef: $labelRef, userType: $userType, additionalNotes: $additionalNotes) {
    ... on LegalMatterMutationResponse {
      code
      success
      message
      legalMatter {
        ...LegalMatterStatus
      }
    }
    ... on MutationError {
      code
      success
      message
      userErrors {
        code
        fieldErrors {
          name
          errors {
            code
            message
          }
        }
      }
    }
  }
}
`)

const legalMatterPreviewModeMutation = gql(`
mutation LegalMatterPreviewMode($firmRef: Key!, $legalMatterKey: Key!) {
  legalMatterPreviewMode(firmRef: $firmRef, legalMatterKey: $legalMatterKey) {
    ... on LegalMatterMutationResponse {
      code
      success
      message
      legalMatter {
        status
        key
      }
    }
    ... on MutationError {
      code
      success
      message
      userErrors {
        code
        fieldErrors {
          name
          errors {
            code
            message
          }
        }
      }
    }
  }
}
`)

interface LegalMatterHook {
  acceptLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          AcceptLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<AcceptLegalMatterMutation>>
  rejectLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          RejectLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<RejectLegalMatterMutation>>
  assignLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          AssignLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<AssignLegalMatterMutation>>
  claimLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          ClaimLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<ClaimLegalMatterMutation>>
  cancelLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          CancelLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<CancelLegalMatterMutation>>
  closeLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          CloseLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<CloseLegalMatterMutation>>
  createLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          CreateLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<CreateLegalMatterMutation>>
  withdrawLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          WithdrawLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<WithdrawLegalMatterMutation>>
  shareLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          ShareLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<ShareLegalMatterMutation>>
  shareRejectLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          LegalMatterShareRejectMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<LegalMatterShareRejectMutation>>
  referredOutLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          LegalMatterReferMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<LegalMatterReferMutation>>
  enterPreviewModeLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          LegalMatterPreviewModeMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<LegalMatterPreviewModeMutation>>
  acceptReferredLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          AcceptReferredLegalMatterMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<AcceptReferredLegalMatterMutation>>
  rejectReferredLegalMatter: (
    mutationOpts:
      | MutationFunctionOptions<
          LegalMatterReferralRejectMutation,
          OperationVariables,
          DefaultContext,
          ApolloCache<any>
        >
      | undefined,
  ) => Promise<FetchResult<LegalMatterReferralRejectMutation>>
  legalMatterLoading: boolean
  legalMatterError: any
}

const useLegalMatter = (): LegalMatterHook => {
  const [acceptLegalMatter, { loading: accepting, error: acceptError }] =
    useMutation<AcceptLegalMatterMutation>(acceptLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterAccept.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterAccept.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [rejectLegalMatter, { loading: rejecting, error: rejectError }] =
    useMutation<RejectLegalMatterMutation>(rejectLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterReject.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterReject.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [assignLegalMatter, { loading: assigning, error: assignError }] =
    useMutation<AssignLegalMatterMutation>(assignLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterAssign.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterAssign.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [claimLegalMatter, { loading: claiming, error: claimError }] =
    useMutation<ClaimLegalMatterMutation>(claimLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterClaim.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterClaim.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [cancelLegalMatter, { loading: cancelling, error: cancelError }] =
    useMutation<CancelLegalMatterMutation>(cancelLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterCancel.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterCancel.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [closeLegalMatter, { loading: closing, error: closeError }] =
    useMutation<CloseLegalMatterMutation>(closeLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterClose.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterClose.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [createLegalMatter, { loading: creating, error: createError }] =
    useMutation<CreateLegalMatterMutation>(createLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterCreate.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [withdrawLegalMatter, { loading: withdrawing, error: withdrawError }] =
    useMutation<WithdrawLegalMatterMutation>(withdrawLegalMatterMutation, {
      update(cache, { data }) {
        if (
          data?.legalMatterWithdraw.__typename === 'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterWithdraw.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    })

  const [shareLegalMatter, { loading: sharing, error: shareError }] =
    useMutation<ShareLegalMatterMutation>(shareLegalMatterMutation)

  const [
    shareRejectLegalMatter,
    { loading: removeMeloading, error: removeMeError },
  ] = useMutation<LegalMatterShareRejectMutation>(
    legalMatterShareRejectMutation,
  )

  const [
    acceptReferredLegalMatter,
    { loading: acceptingReferred, error: acceptReferralError },
  ] = useMutation<AcceptReferredLegalMatterMutation>(
    acceptReferredLegalMatterMutation,
    {
      update(cache, { data }) {
        if (
          data?.legalMatterReferralAccept.__typename ===
          'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterReferralAccept.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    },
  )

  const [
    referredOutLegalMatter,
    { loading: referringOut, error: referOutError },
  ] = useMutation<LegalMatterReferMutation>(legalMatterReferMutation, {
    update(cache, { data }) {
      if (data?.legalMatterRefer.__typename === 'LegalMatterMutationResponse') {
        cache.writeFragment({
          data: data.legalMatterRefer.legalMatter,
          fragment: LegalMatterStatusFragmentDoc,
        })
        cache.evict({ fieldName: 'legalMatters' })
      }
    },
  })

  const [
    enterPreviewModeLegalMatter,
    { loading: enteringPreview, error: enteringPreviewError },
  ] = useMutation<LegalMatterPreviewModeMutation>(
    legalMatterPreviewModeMutation,
    {
      update(cache, { data }) {
        if (
          data?.legalMatterPreviewMode.__typename ===
          'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterPreviewMode.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    },
  )

  const [
    rejectReferredLegalMatter,
    { loading: rejectingReferred, error: rejectReferralError },
  ] = useMutation<LegalMatterReferralRejectMutation>(
    legalMatterReferralRejectMutation,
    {
      update(cache, { data }) {
        if (
          data?.legalMatterReferralReject.__typename ===
          'LegalMatterMutationResponse'
        ) {
          cache.writeFragment({
            data: data.legalMatterReferralReject.legalMatter,
            fragment: LegalMatterStatusFragmentDoc,
          })
          cache.evict({ fieldName: 'legalMatters' })
        }
      },
    },
  )

  const legalMatterLoading =
    accepting ||
    rejecting ||
    assigning ||
    claiming ||
    cancelling ||
    closing ||
    creating ||
    withdrawing ||
    sharing ||
    referringOut ||
    acceptingReferred ||
    rejectingReferred ||
    enteringPreview ||
    removeMeloading

  const legalMatterError =
    acceptError ??
    rejectError ??
    assignError ??
    claimError ??
    cancelError ??
    closeError ??
    createError ??
    withdrawError ??
    shareError ??
    referOutError ??
    acceptReferralError ??
    rejectReferralError ??
    enteringPreviewError ??
    removeMeError

  return {
    acceptLegalMatter,
    rejectLegalMatter,
    assignLegalMatter,
    claimLegalMatter,
    cancelLegalMatter,
    closeLegalMatter,
    createLegalMatter,
    shareLegalMatter,
    shareRejectLegalMatter,
    withdrawLegalMatter,
    acceptReferredLegalMatter,
    rejectReferredLegalMatter,
    referredOutLegalMatter,
    enterPreviewModeLegalMatter,
    legalMatterLoading,
    legalMatterError,
  }
}

export default useLegalMatter
