/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useRef } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import ItemBoundingBox from '@components/atoms/ItemBoundingBox'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { type Scalars } from '@generatedTypes/graphql'
import useScrollIntoView from '@utils/useScrollIntoView'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import FormInputText from '@components/forms/FormInputText'
import FormInputSelect from '@components/forms/FormInputSelect'

export interface SelectAssigneeProps {
  value: Scalars['Key']
  label: string
}

interface DataProps {
  name?: string
  description?: string
  assignedTo?: Scalars['Key']
}

export type TaskFormProps = DataProps & {
  taskName?: string
  taskDescription?: string
  namePlaceholder?: string
  descriptionPlaceholder?: string
  textButton?: string
  textCancelButton?: string
  serverError?: string[]
  selectAssigneeProps?: SelectAssigneeProps[]
  isSubmitting: boolean
  onHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClickButton: (data: DataProps) => void
  onClickCancelButton: () => void
}

const CreateTaskBox = ({
  taskName,
  taskDescription,
  assignedTo = '',
  namePlaceholder = 'Enter task name',
  descriptionPlaceholder = 'Enter task description',
  textButton = 'Save',
  textCancelButton = 'Cancel',
  serverError,
  selectAssigneeProps,
  isSubmitting = false,
  onHandleChange,
  onClickButton,
  onClickCancelButton,
}: TaskFormProps): JSX.Element => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors: validationErrors },
  } = useForm<DataProps>({
    defaultValues: {
      name: taskName ?? '',
      description: taskDescription ?? '',
      assignedTo: assignedTo || '',
    },
  })

  const formRef = useRef<HTMLFormElement>(null)

  const onSubmit: SubmitHandler<DataProps> = (data) => {
    onClickButton(data)
  }

  useScrollIntoView(formRef, { behavior: 'smooth', block: 'center' })

  register('name', {
    required: { value: true, message: 'Task name is required' },
    pattern: {
      value: /\w+\b/i,
      message: 'Please enter a valid value',
    },
    maxLength: 80,
  })
  register('description', {
    pattern: {
      value: /\w+\b/i,
      message: 'Please enter a valid value',
    },
  })
  register('assignedTo', {
    required: { value: true, message: 'Task assignment is required' },
  })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      data-testid="create-task-form"
      ref={formRef}
    >
      <ItemBoundingBox variant="outlineBox">
        <Grid container justifyContent="space-between">
          <Grid size={{ xs: 8, sm: 6 }}>
            <FormInputText
              name="name"
              control={control}
              label="Name"
              fullWidth
              placeholder={namePlaceholder}
              error={!!validationErrors?.name}
              helperText={validationErrors?.name?.message}
              id="taskName"
              size="small"
              variant="standard"
              data-testid="create-task-input-name"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 12 }} my={2}>
            <FormInputText
              name="description"
              control={control}
              label="description"
              fullWidth
              placeholder={descriptionPlaceholder}
              error={!!validationErrors?.description}
              helperText={validationErrors?.description?.message}
              rows={2}
              id="taskDescription"
              variant="standard"
              data-testid="create-task-input-description"
            />
          </Grid>
          <Grid size={{ xs: 8, sm: 6 }} my={2}>
            <FormControl
              fullWidth
              size="small"
              error={!(validationErrors?.assignedTo === undefined)}
            >
              <InputLabel id="assigned-to-label">Assigned to</InputLabel>
              <FormInputSelect
                name="assignedTo"
                label="Assigned to"
                control={control}
                optionItems={selectAssigneeProps ?? []}
                selectedItem={assignedTo}
                data-testid="assign-task-dropdown"
              />
              <FormHelperText>
                {validationErrors?.assignedTo?.message?.toString()}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container direction={'row'}>
            {serverError?.map((e) => (
              <Grid>
                <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                  {e}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {textButton !== undefined && textCancelButton !== undefined ? (
            <Grid container justifyContent="flex-end">
              <Grid marginX={1}>
                <Button
                  disableElevation
                  variant="text"
                  color="secondary"
                  data-testid="create-task-cancel-button"
                  onClick={onClickCancelButton}
                  sx={{ textTransform: 'none' }}
                >
                  {textCancelButton}
                </Button>
              </Grid>
              <Grid marginX={1}>
                <Button
                  loading={isSubmitting}
                  disableElevation
                  variant="contained"
                  color="primary"
                  data-testid="create-task-text-button"
                  type="submit"
                >
                  {textButton}
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </ItemBoundingBox>
    </form>
  )
}

export default CreateTaskBox
