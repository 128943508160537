import {
  createBrowserRouter,
  createRoutesFromElements,
  generatePath,
  Route,
} from 'react-router-dom'
import AnonymousUser from '@models/AnonymousUser'
import BaseUser from '@models/BaseUser'
import FirmUser from '@models/FirmUser'
import SubscriberUser from '@models/SubscriberUser'
import AuthPage from '@components/pages/Auth'
import CheckoutFinalized from '@components/pages/CheckoutFinalized'
import CreateLegalMatter, {
  createLegalMatterLoader,
} from '@components/pages/CreateLegalMatter'
import FirmManagementPage from '@components/pages/FirmManagement'
import FirmInvite from '@components/pages/FirmInvite'
import PasswordReset from '@components/pages/PasswordReset'
import SubscriptionPage from '@components/pages/SubscriptionPage'
import SignedOut from '@components/pages/SignedOut'
import SubscriberRegistration from '@components/pages/SubscriberRegistration'
import FirmUserRegistration, {
  registerFirmUserLoader,
} from '@components/pages/FirmUserRegistration'
import { type UserTypes } from '@utils/useAccessRules'
import RedirectHandler from '@components/atoms/RedirectHandler'
import UserProfile from '@components/pages/UserProfile'
import AuthProviderUser from '@models/AuthProviderUser'
import CheckoutPage from '@components/pages/Checkout'
import LegalServiceSelection from '@components/pages/LegalServiceSelection'
import SignInWithEmailLink from '@components/pages/SignInWithEmailLink'
import ErrorScreen from '@components/organisms/ErrorScreen'
import PageLayout from '@components/templates/PageLayout'
import LegalMatterDetail from '@components/pages/LegalMatterDetail'
import LegalMatterListing from '@components/pages/LegalMatterListing'

export interface RouteEntry {
  path: string
  name: string
  navbar?: boolean
  allowedUserTypes?: UserTypes
  requiresRole?: string
}

const _routes: Record<string, RouteEntry> = {
  signedOut: {
    path: '/signed-out',
    name: 'Page visible to users after they sign out',
    allowedUserTypes: [AnonymousUser],
  },
  login: {
    path: '/login',
    name: 'Sign In',
    allowedUserTypes: [AnonymousUser],
    navbar: true,
  },
  signup: {
    path: '/sign-up',
    name: 'Sign Up',
    allowedUserTypes: [AuthProviderUser],
  },
  signInWithEmailLink: {
    path: '/login/email-link',
    name: 'Sign in with email link',
    allowedUserTypes: [AnonymousUser],
  },
  forgotPassword: {
    path: '/forgot-password',
    name: 'Forgot Password',
    allowedUserTypes: [AnonymousUser],
  },
  firmUserSignup: {
    path: '/firms/sign-up/:userKind/:inviteKey',
    name: 'Sign Up',
    allowedUserTypes: [AuthProviderUser],
  },
  // @TODO this should go away when legal matter routes for firm
  // users and lawyer users are unified into a common route
  lawyerUserSignup: {
    path: '/lawyers/sign-up/:userKind/:inviteKey',
    name: 'Sign Up',
    allowedUserTypes: [AuthProviderUser],
  },
  createLegalMatter: {
    path: '/legal-matters/:legalMatterKind/:legalMatterKindKey',
    name: 'Request Legal Service',
    allowedUserTypes: [SubscriberUser],
    requiresRole: 'legalMatters.creator',
  },
  legalServiceSelection: {
    path: '/create-matter-screen',
    name: 'Create Legal Matter',
    allowedUserTypes: [SubscriberUser],
    requiresRole: 'legalMatters.creator',
  },
  legalMatterListing: {
    path: '/legal-matters',
    name: 'Legal Matters',
    allowedUserTypes: [BaseUser, FirmUser, SubscriberUser],
    navbar: true,
  },
  legalMatterDetail: {
    path: '/legal-matters/:legalMatterKey',
    name: 'Legal Matter Details',
    allowedUserTypes: [BaseUser, FirmUser, SubscriberUser],
  },
  firmInvite: {
    path: '/invites/:inviteKey',
    name: 'Invitation to LegalFix',
    allowedUserTypes: [
      AnonymousUser,
      AuthProviderUser,
      FirmUser,
      SubscriberUser,
    ],
  },
  checkoutSuccess: {
    path: '/checkout/success/:SESSION_ID',
    name: 'Checkout success',
  },
  checkoutFailure: {
    path: '/checkout/failure/',
    name: 'Checkout failure',
  },
  checkoutManageProducts: {
    path: '/checkout/manage',
    name: 'Manage Plans',
    allowedUserTypes: [SubscriberUser],
  },
  firmManagement: {
    path: '/firms/management',
    name: 'Firm Management',
    requiresRole: 'firms.manager',
    navbar: true,
  },
  userProfile: {
    path: '/profile',
    name: 'Your Profile',
    allowedUserTypes: [BaseUser, FirmUser, SubscriberUser],
  },
  userSubscriptionStatus: {
    path: '/profile/subscription',
    name: 'Your Subscription',
    allowedUserTypes: [SubscriberUser],
  },
}

export const redirects = {
  '/firms/legal-matters': '/legal-matters',
  '/firms/legal-matters/:legalMatterKey': '/legal-matters/:legalMatterKey',
  '/lawyers/legal-matters': '/legal-matters',
  '/lawyers/legal-matters/:legalMatterKey': '/legal-matters/:legalMatterKey',
  '/subscribers/legal-matters': '/legal-matters',
  '/subscribers/legal-matters/:legalMatterKey':
    '/legal-matters/:legalMatterKey',
}

interface RouteWithParam extends RouteEntry {
  param: (param: any) => string
}

const param = (path: string) => (param: any) => generatePath(path, param)

export const routes: Record<string, RouteWithParam> = Object.keys(
  _routes,
).reduce((acc, key) => {
  const obj = { ..._routes[key], param: param(_routes[key].path) }
  acc[key] = obj
  return acc
}, {})

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RedirectHandler />} errorElement={<PageLayout />}>
      <Route
        path="/error-not-found"
        element={<ErrorScreen content="Page not found" />}
      />
      <Route path={routes.signedOut.path} element={<SignedOut />} />
      <Route path={routes.login.path} element={<AuthPage />} />
      <Route path={routes.forgotPassword.path} element={<PasswordReset />} />
      <Route path={routes.firmInvite.path} element={<FirmInvite />} />
      <Route path={routes.userProfile.path} element={<UserProfile />} />
      <Route path={routes.signup.path} element={<SubscriberRegistration />} />
      <Route
        path={routes.signInWithEmailLink.path}
        element={<SignInWithEmailLink />}
      />
      <Route
        path={routes.firmUserSignup.path}
        loader={registerFirmUserLoader}
        element={<FirmUserRegistration />}
        errorElement={<ErrorScreen content="Something went wrong." />}
      />
      <Route
        path={routes.lawyerUserSignup.path}
        loader={registerFirmUserLoader}
        element={<FirmUserRegistration />}
        errorElement={<ErrorScreen content="Something went wrong." />}
      />
      <Route
        path={routes.legalMatterListing.path}
        element={<LegalMatterListing />}
      />
      <Route
        path={routes.legalMatterDetail.path}
        element={<LegalMatterDetail />}
      />
      <Route
        path={routes.userSubscriptionStatus.path}
        element={<SubscriptionPage />}
      />
      <Route
        path={routes.checkoutManageProducts.path}
        element={<CheckoutPage />}
      />
      <Route
        path={routes.checkoutSuccess.path}
        element={<CheckoutFinalized />}
      />
      <Route
        path={routes.checkoutFailure.path}
        element={<CheckoutFinalized />}
      />
      <Route
        path={routes.createLegalMatter.path}
        element={<CreateLegalMatter />}
        loader={createLegalMatterLoader}
        errorElement={<ErrorScreen content="Page not found" />}
      />
      <Route
        path={routes.legalServiceSelection.path}
        element={<LegalServiceSelection />}
        errorElement={<ErrorScreen content="Page not found" />}
      />
      <Route
        path={routes.firmManagement.path}
        element={<FirmManagementPage />}
      />
      <Route path="*" element={<div>Stub</div>} />
    </Route>,
  ),
)

export default router
