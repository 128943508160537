import User from './User'
import { type FirmUserProfileFragment } from '@generatedTypes/graphql'

type FirmUserProfile = FirmUserProfileFragment & {
  photoURL: string | null | undefined
}

export default class FirmUser extends User {
  key: string
  profile: FirmUserProfile

  constructor(profile: FirmUserProfile) {
    super(profile.aclIdentities, profile.roles)
    this.key = profile.key
    this.profile = profile
  }
}
