import { useConnectionMonitor } from '@utils/useConnectionMonitor'
import Alert from '@mui/material/Alert'

const ConnectionMonitor = (): JSX.Element => {
  const { connected } = useConnectionMonitor()

  if (!connected) {
    return <Alert severity="error">Your network connection is down. </Alert>
  }

  return <></>
}

export default ConnectionMonitor
