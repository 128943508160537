import React, { useState, useEffect } from 'react'
import { intervalToDuration } from 'date-fns'

type CountdownProps = {
  limitTime: string
  label?: string
}

const Countdown: React.FC<CountdownProps> = ({ limitTime, label }) => {
  const targetDate = new Date(limitTime)
  const getUTCNow = () => new Date()

  const calculateDuration = () => {
    const duration = intervalToDuration({ start: getUTCNow(), end: targetDate })
    const totalHours = (duration.days ?? 0) * 24 + (duration.hours ?? 0)
    return {
      hours: totalHours,
      minutes: duration.minutes ?? 0,
      seconds: duration.seconds ?? 0,
    }
  }

  const [timeLeft, setTimeLeft] = useState(calculateDuration())

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateDuration())
    }, 1000)

    return () => clearInterval(interval)
  }, [targetDate])

  return (
    <div>
      {timeLeft.hours !== undefined &&
      timeLeft.minutes !== undefined &&
      timeLeft.seconds !== undefined ? (
        <span>
          {label ? `${label} ` : ''}{' '}
          {`${timeLeft.hours.toString().padStart(2, '0')}:${timeLeft.minutes
            .toString()
            .padStart(2, '0')}:${timeLeft.seconds.toString().padStart(2, '0')}`}
        </span>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Countdown
