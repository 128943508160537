import useTestId from '@utils/useTestId'
import { type SubcsriptionsFilterStatusMap } from '@utils/subscriptionStatusMapper'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select, { type SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'

interface FilterValues {
  value: string
  label: string
}

interface FiltersSubscriptionsProps {
  selectedStatus: string
  statusList: SubcsriptionsFilterStatusMap
  onChange: (value: string) => void
  'data-testid'?: string
}

const FiltersSubscriptions = ({
  selectedStatus,
  statusList,
  onChange,
  ...props
}: FiltersSubscriptionsProps): JSX.Element => {
  const testId = useTestId(props)
  const handleDropdownChange = (event: SelectChangeEvent): void => {
    const value = event.target.value
    onChange(value)
  }

  const mapNamesAndValues = (
    object: SubcsriptionsFilterStatusMap,
  ): FilterValues[] => {
    return Object.entries(object).map(([key, value]) => ({
      value: key,
      label: value.label,
    }))
  }

  const mappedValues = mapNamesAndValues(statusList)

  return (
    <Grid container spacing={2} mb={2} alignItems="center">
      <Grid item>
        <Typography>Filter by Status:</Typography>
      </Grid>
      <Grid item>
        <Select
          {...testId}
          value={selectedStatus}
          onChange={handleDropdownChange}
          sx={{ minWidth: '200px' }}
        >
          {mappedValues.map((item, idx: number) => (
            <MenuItem value={item.value} key={idx}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}

export default FiltersSubscriptions
