import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { NetworkStatus, useQuery } from '@apollo/client'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Typography from '@mui/material/Typography'
import { paginationLimits } from '@/config'
import model from '@/models'
import { type DialogFiles, type Errors } from '@interfaces/DialogControlType'
import { routes } from '@/routes'
import {
  CursorDirection,
  Permission,
  SubscriberLegalMatterDetailDocument,
  LegalMatterTasksDocument,
  SubscriberLegalMatterDetailQuery,
  SubscriberLegalMatterDetailQueryVariables,
} from '@generatedTypes/graphql'
import SubscriberUser from '@models/SubscriberUser'
import ApplicationError from '@components/molecules/ApplicationError'
import { SubscriberLegalMatterLoading } from '@components/molecules/LoadingSkeletons'
import TabPanel from '@components/atoms/TabPanel'
import LegalMatterSidebar from '@components/organisms/LegalMatterSidebar'
import LawFirmContact from '@components/molecules/LawFirmContact'
import { labelSlugs } from '@/constants/index'
import TwoColumnLayout from '@components/templates/TwoColumnLayout'
import CalendarEventListItem from '@components/organisms/CalendarEventListItem'
import IntakeFormStep from '@components/forms/IntakeFormStep'
import AnimatedList from '@components/molecules/AnimatedList'
import DropdownPeriodSelector from '@components/atoms/DropdownPeriodSelector'
import { getPastDate } from '@utils/dateUtils'
import ConfirmationDialog, {
  type ConfirmationDialogProps,
} from '@components/molecules/ConfirmationDialog'
import SubscriberTaskListing from '@components/data/SubscriberTaskListing'
import useCurrentUser from '@utils/useCurrentUser'
import { useNotificationHandler } from '@utils/useNotificationHandler'
import { SubscriberLegalMatterStatusLabels } from '@utils/StatusMapper'
import { EventProviderContext } from '@view/providers/EventProvider'
import useLegalMatter from '@utils/useLegalMatter'
import useDocuments from '@utils/useDocuments'

interface DialogControlProps extends ConfirmationDialogProps {
  files?: DialogFiles
  errors?: Errors
  textBoxContent?: string
  documentKey?: string
}

type TabPanelType = 'INTAKEFORM' | 'LEGALMATTER'

const SubscriberLegalMatter = (): JSX.Element | null => {
  const componentIdentifier = SubscriberLegalMatter.name
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { legalMatterKey } = useParams()
  const { user, loading: userLoading } = useCurrentUser()
  const { registerEvents, unregisterEvents } = useContext(EventProviderContext)
  const { enqueueNotification } = useNotificationHandler()
  const [selectedTab, setSelectedTab] = useState<TabPanelType>('LEGALMATTER')
  const navigate = useNavigate()
  const { deleteDocument, downloadDocument, uploadDocument } = useDocuments()
  const { cancelLegalMatter } = useLegalMatter()

  useEffect(() => {
    return () => {
      unregisterEvents(componentIdentifier)
    }
  }, [unregisterEvents, componentIdentifier])

  useEffect(() => {
    registerEvents(
      componentIdentifier,
      [
        'CalendarEventCreated',
        'CalendarEventDeleted',
        'CalendarEventUpdated',
        'LegalMatterAssigned',
        'LegalMatterClaimed',
        'LegalMatterClosed',
        'LegalMatterDocumentDeleted',
        'LegalMatterDocumentUpdated',
        'LegalMatterRejected',
        'LegalMatterWithdrawn',
        'TaskCreated',
      ],
      [SubscriberLegalMatterDetailDocument, LegalMatterTasksDocument],
      enqueueNotification,
    )
  }, [registerEvents, enqueueNotification, componentIdentifier])

  const dialogControlIntialState: DialogControlProps = {
    open: false,
    title: <></>,
    content: <></>,
    actions: <></>,
    documentKey: '',
    textBoxContent: '',
    errors: {
      message: undefined,
    },
  }

  const downloadRef = useRef<HTMLAnchorElement>(null)
  const [dialogControlProps, setDialogControlProps] = useState(
    dialogControlIntialState,
  )
  const [eventsPeriod, setEventsPeriod] = useState<number>(7)
  const [eventsPeriodDateTime, setEventsPeriodDateTime] = useState<
    string | Date | null
  >(getPastDate(eventsPeriod, 'UTC'))

  const { data, networkStatus, fetchMore } = useQuery<
    SubscriberLegalMatterDetailQuery,
    SubscriberLegalMatterDetailQueryVariables
  >(SubscriberLegalMatterDetailDocument, {
    variables: {
      key: legalMatterKey ?? null,
      since: eventsPeriodDateTime,
      calendarLimit: paginationLimits.calendarEvents,
      calendarCursor: null,
      calendarCursorDirection: CursorDirection.Next,
      documentsLimit: paginationLimits.documents,
      documentsCursor: null,
      documentsCursorDirection: CursorDirection.Next,
    },
    notifyOnNetworkStatusChange: true,
    skip: userLoading,
  })

  if (userLoading || networkStatus === NetworkStatus.loading) {
    return <SubscriberLegalMatterLoading />
  }

  if (user instanceof SubscriberUser === false) {
    return (
      <ApplicationError
        message={
          <Typography>
            No valid user could be resolved. Please reload in a few seconds.
          </Typography>
        }
      />
    )
  }

  const legalMatter = data?.legalMatters?.edges?.[0]?.node

  if (!data || legalMatter == null) {
    return (
      <ApplicationError
        message={
          <Typography>
            This legal matter does not exist.{' '}
            <Link
              onClick={() => {
                navigate(routes.legalMatterListing.path)
              }}
            >
              Go back to My Legal Matters.
            </Link>
          </Typography>
        }
      />
    )
  }

  const legalMatterModel = new model.LegalMatter(
    data.legalMatters.edges[0].node,
  )
  const assignmentInProgress = legalMatterModel.assignmentInProgress()

  if (assignmentInProgress && selectedTab !== 'INTAKEFORM') {
    setSelectedTab('INTAKEFORM')
  }

  const handleCancelThisMatter = (): void => {
    setDialogControlProps({
      ...dialogControlProps,
      open: true,
      title: (
        <>
          <Typography variant="h1" gutterBottom>
            Confirm to cancel this legal matter
          </Typography>
        </>
      ),
      content: (
        <>
          <Typography>
            Are you sure you want to cancel this legal matter?
          </Typography>
        </>
      ),
      actions: (
        <>
          <Button
            onClick={() => {
              setDialogControlProps(dialogControlIntialState)
            }}
            autoFocus
            variant="contained"
            color="error"
            data-testid="confirm-dialog-btn-cancel"
          >
            Close this window
          </Button>
          <Button
            onClick={() => {
              handleDialogActionConfirm('cancelLegalMatter')
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-dialog-btn-accept"
          >
            Confirm cancellation
          </Button>
        </>
      ),
    })
  }

  const handleDocumentDownload = (documentPath: string): void => {
    const downloadJob = downloadDocument(documentPath)
    const snackKey = enqueueSnackbar('Downloading ...', {
      variant: 'info',
    })
    downloadJob.onComplete(({ fileName, objectUrl }) => {
      return new Promise((resolve) => {
        if (downloadRef.current) {
          // Set the href to the blob URL and the download attribute to the file name
          downloadRef.current.href = objectUrl
          downloadRef.current.download = fileName!

          // Trigger the download
          downloadRef.current?.click()
          resolve()
          closeSnackbar(snackKey)
        }
      })
    })
    downloadJob.onError(({ error }) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      })
    })
  }

  const handleDocumentDelete = (documentKey: string): void => {
    const document = legalMatterModel
      .get('documents')
      .edges.find((d) => d.node.key === documentKey)
    if (document !== undefined) {
      setDialogControlProps({
        open: true,
        title: (
          <>
            <Typography variant="h1" gutterBottom>
              Confirm deletion of document
            </Typography>
          </>
        ),
        content: (
          <>
            <Typography>
              Are you sure you want to delete the document{' '}
              <strong>{document.node.name}</strong> ?
            </Typography>
          </>
        ),
        actions: (
          <>
            <Button
              onClick={() => {
                setDialogControlProps(dialogControlIntialState)
              }}
              autoFocus
              variant="contained"
              color="error"
              data-testid="confirm-dialog-btn-cancel"
            >
              Cancel deletion
            </Button>
            <Button
              onClick={() => {
                handleDialogActionConfirm('deleteAttachedDocument', documentKey)
              }}
              autoFocus
              variant="contained"
              color="primary"
              data-testid="confirm-dialog-btn-accept"
            >
              Confirm deletion
            </Button>
          </>
        ),
      })
    }
  }

  const onClickLoadMoreCalendarEvents = (endCursor: string): void => {
    void fetchMore({
      variables: {
        calendarCursor: endCursor,
      },
    })
  }

  const handleTabChange = (
    event: React.SyntheticEvent,
    newSelectedTab: TabPanelType,
  ): void => {
    setSelectedTab(newSelectedTab)
  }

  const handleEventPeriodChange = (value: number): void => {
    setEventsPeriod(value)
    setEventsPeriodDateTime(getPastDate(value, 'UTC'))
  }

  const handleDialogActionConfirm = (action: string, itemKey = ''): void => {
    switch (action) {
      case 'deleteAttachedDocument':
        if (itemKey !== '') {
          void deleteDocument({
            variables: {
              documentKey: itemKey,
            },
            onCompleted: (data) => {
              if (!data.legalMatterDocumentDelete.success) {
                enqueueSnackbar(data.legalMatterDocumentDelete.message, {
                  variant: 'error',
                })
              }

              setDialogControlProps(dialogControlIntialState)
            },
            onError: () => {
              setDialogControlProps(dialogControlIntialState)
              enqueueSnackbar(
                'There was an error in removing the attached document, try again in a few seconds',
                {
                  variant: 'error',
                },
              )
            },
          })
        }
        break
      case 'cancelLegalMatter':
        setDialogControlProps((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Canceling this legal matter
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>Please wait...</Typography>
            </>
          ),
          actions: <></>,
        }))
        void cancelLegalMatter({
          variables: {
            legalMatterKey,
          },
          onCompleted: () => {
            setDialogControlProps(dialogControlIntialState)
          },
          onError: (error) => {
            setDialogControlProps((prevState) => ({
              ...prevState,
              errors: { message: error.message },
            }))
            enqueueSnackbar(
              'There was a problem in processing the request, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
    }
  }

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          onChange={handleTabChange}
          value={selectedTab}
          aria-label="Insured tabs"
        >
          <Tab
            label={labelSlugs.LEGAL_MATTER_INFO_TAB}
            value="LEGALMATTER"
            data-testid="LEGALMATTER-tab"
            aria-label="LEGALMATTER tab"
            disabled={assignmentInProgress}
          />
          <Tab
            label={labelSlugs.LEGAL_MATTER_INTAKE_TAB}
            data-testid="INTAKEFORM-tab"
            aria-label="INTAKEFORM tab"
            value="INTAKEFORM"
          />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index="INTAKEFORM">
        <IntakeFormStep
          readonly
          intakeDataRef={legalMatter.intakeDataRefs?.[0]}
        ></IntakeFormStep>
      </TabPanel>
      <TabPanel value={selectedTab} index="LEGALMATTER">
        <TwoColumnLayout>
          <LegalMatterSidebar
            legalMatterFragment={legalMatter}
            legalMatterStatusLabels={SubscriberLegalMatterStatusLabels}
            onDocumentClick={handleDocumentDownload}
            onDocumentDelete={handleDocumentDelete}
            user={user}
          >
            <LawFirmContact
              lawyerName={legalMatter.assignedLawyer?.firstName}
              lawyerLastName={legalMatter.assignedLawyer?.lastName}
              lawyerEmail={legalMatter.assignedLawyer?.email ?? undefined}
              lawyerPhone={legalMatter.assignedLawyer?.phone ?? undefined}
              firmName={legalMatter.assignedLawyer?.firm?.name ?? undefined}
              firmEmail={legalMatter.assignedLawyer?.firm?.email ?? undefined}
              firmPhone={legalMatter?.assignedLawyer?.firm?.phone ?? undefined}
            />
            {legalMatterModel.acl.allows(
              user.aclIdentities,
              Permission.LegalMattersCancel,
            ) &&
              legalMatterModel.canBeCanceled() && (
                <>
                  <hr />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleCancelThisMatter}
                    sx={{ width: '100%' }}
                    data-testid="cancel-legalmatter-button"
                  >
                    Cancel this request
                  </Button>
                </>
              )}
          </LegalMatterSidebar>
          <Box my={2}>
            {assignmentInProgress ? (
              <Alert severity="info">
                This legal matter hasn't been assigned yet
              </Alert>
            ) : (
              <>
                <Typography variant="h2" gutterBottom>
                  Consultations
                </Typography>
                <Divider sx={{ mb: 1 }} />
                <Grid
                  item
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  xs={12}
                  spacing={2}
                  mb={1}
                >
                  <Grid item>Showing Events from:</Grid>
                  <Grid item>
                    <DropdownPeriodSelector
                      value={eventsPeriod.toString()}
                      onChangeValue={handleEventPeriodChange}
                    ></DropdownPeriodSelector>
                  </Grid>
                </Grid>
                <Divider sx={{ mt: 1, mb: 2 }} />
                {legalMatter.calendarEvents?.edges?.length === 0 && (
                  <Box sx={{ mt: 2 }}>
                    <Alert severity="info" icon={false}>
                      <Typography variant="h5" gutterBottom>
                        No Scheduled Consultations
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        The attorney has not scheduled any consultations for
                        this legal matter.
                      </Typography>
                    </Alert>
                  </Box>
                )}
                {legalMatter.calendarEvents != null ? (
                  <>
                    <AnimatedList animateItemKey={null}>
                      {legalMatter.calendarEvents.edges.map((event) => (
                        <CalendarEventListItem
                          userTimeZone={user?.profile?.timeZone}
                          disableMenu={true}
                          key={event.node.key}
                          calendarEvent={event.node}
                        />
                      ))}
                    </AnimatedList>
                    {legalMatter.calendarEvents.pageInfo.hasNextPage === true &&
                      legalMatter.calendarEvents.pageInfo.endCursor != null && (
                        <Button
                          loading={networkStatus === NetworkStatus.fetchMore}
                          disableElevation
                          variant="contained"
                          color="primary"
                          role="button"
                          aria-label="Load More"
                          onClick={() => {
                            onClickLoadMoreCalendarEvents(
                              legalMatter.calendarEvents.pageInfo.endCursor!,
                            )
                          }}
                          data-testid="events-load-more-button"
                        >
                          Load More
                        </Button>
                      )}
                  </>
                ) : (
                  <Typography variant="h4">
                    {labelSlugs.NO_COSULTATIONS_AVAILABLE}
                  </Typography>
                )}
                <Box my={2}>
                  <Divider sx={{ mb: 2 }} />
                  <SubscriberTaskListing
                    legalMatterKey={legalMatterKey}
                    isReadOnly={
                      legalMatterModel.isReadOnly() ||
                      !user.hasAnActiveSubscription()
                    }
                    handleDocumentDownload={handleDocumentDownload}
                    handleDocumentDelete={handleDocumentDelete}
                    handleDocumentUpload={uploadDocument}
                  />
                </Box>
              </>
            )}
          </Box>
        </TwoColumnLayout>
      </TabPanel>
      <ConfirmationDialog
        open={dialogControlProps.open}
        onClose={() => {
          setDialogControlProps(dialogControlIntialState)
        }}
        title={dialogControlProps.title}
        content={dialogControlProps.content}
        actions={dialogControlProps.actions}
        errorMessage={dialogControlProps?.errorMessage}
      />
      <Box style={{ display: 'none' }}>
        <a ref={downloadRef} style={{ display: 'none' }} target="_blank" />
      </Box>
    </Box>
  )
}

export default SubscriberLegalMatter
