import { ThemeProvider } from '@mui/material/styles'
import { type ReactNode } from 'react'

import theme from './theme'

interface Props {
  children: ReactNode
}

export const ThemeRegistry = ({ children }: Props): JSX.Element => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}
