import { type MutationHookOptions, useMutation } from '@apollo/client'
import {
  type BillingPortalSessionCreateMutation,
  type BillingPortalSessionCreateMutationVariables,
} from '@generatedTypes/graphql'
import { gql } from '@generatedTypes/gql'

const billingPortalSession = gql(`
  mutation BillingPortalSessionCreate($returnUrl: String!) {
    billingPortalSessionCreate(returnUrl: $returnUrl) {
      ... on SessionUrlResponse {
        code
        message
        sessionUrl
        success
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          fieldErrors {
            name
            errors {
              message
              code
            }
          }
          code
        }
      }
    }
  }`)

const useBillingPortalSession = (
  baseOptions?: MutationHookOptions<
    BillingPortalSessionCreateMutation,
    BillingPortalSessionCreateMutationVariables
  >,
) => {
  return useMutation<
    BillingPortalSessionCreateMutation,
    BillingPortalSessionCreateMutationVariables
  >(billingPortalSession, baseOptions)
}

export default useBillingPortalSession
