import { useEffect, useState } from 'react'
import { onAuthStateChanged, type User } from 'firebase/auth'
import { setContext } from '@apollo/client/link/context'
import { auth } from '@/firebaseConfig'
import log from 'loglevel'

export interface Auth {
  authLoading: boolean
  isAuthenticated: boolean
  user: User | null
}

export const withToken = setContext(async (_, { headers }) => {
  if (auth.currentUser == null) {
    return { headers: { ...headers } }
  }
  const token = await auth.currentUser.getIdToken()

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }
})

const defaultState = {
  authLoading: true,
  isAuthenticated: false,
  user: null,
}

const useFirebaseAuth = (): Auth => {
  const [authState, setAuthState] = useState<Auth>(defaultState)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      log.debug('User is ready', user)
      setAuthState({
        ...defaultState,
        authLoading: false,
        isAuthenticated: user != null,
        user,
      })
    })
  }, [])

  return authState
}

export default useFirebaseAuth
