import React from 'react'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import NameValueLabel from '@components/atoms/NameValueLabel'
import { labelSlugs } from '@/constants/strings'
import { type ProductDetails } from '@generatedTypes/graphql'

interface ClientContactInformationProps {
  subscriberName?: string
  productDetails?: Pick<ProductDetails, 'id' | 'name'>[]
  phone?: string
  email?: string
  onClickProductLink?: (
    item: Pick<ProductDetails, 'id' | 'name'>,
    idx: number,
  ) => void
}

const ClientContactInformation = ({
  subscriberName,
  productDetails,
  phone,
  email,
  onClickProductLink,
}: ClientContactInformationProps): JSX.Element => {
  return (
    <Grid container justifyContent="space-between" alignItems="start">
      <Grid item xs={12} sx={{ mb: 1 }}>
        <NameValueLabel
          value="Client Contact Information"
          typographyProps={{ variant: 'h5' }}
        />
      </Grid>
      <Grid item xs={12}>
        <NameValueLabel
          name={labelSlugs.CLIENT_NAME}
          value={subscriberName ?? ''}
          typographyProps={{ variant: 'body1' }}
        />
      </Grid>
      <Grid item xs={12}>
        <NameValueLabel
          name={labelSlugs.PHONE_LABEL}
          value={phone ?? ''}
          typographyProps={{ variant: 'body1' }}
        />
      </Grid>
      <Grid item xs={12}>
        <NameValueLabel
          name={labelSlugs.EMAIL_LABEL}
          value={email ?? ''}
          typographyProps={{ variant: 'body1' }}
        />
      </Grid>
      <Grid item xs={12}>
        {productDetails !== undefined ? (
          <List disablePadding dense={true}>
            <ListItemText
              primary="Legal Plan Benefits"
              primaryTypographyProps={{ variant: 'h3' }}
            />
            {productDetails?.map((product, idx) => (
              <ListItem key={product.id} disableGutters disablePadding>
                <Link
                  variant="subtitle2"
                  data-testid="subscriber-Plan"
                  onClick={
                    onClickProductLink !== undefined
                      ? () => {
                          onClickProductLink(product, idx)
                        }
                      : undefined
                  }
                >
                  {product.name}
                </Link>
              </ListItem>
            ))}
          </List>
        ) : (
          'No subscriptions found.'
        )}
      </Grid>
    </Grid>
  )
}

export default ClientContactInformation
