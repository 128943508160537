import { UsTimeZone } from '@/@types/generated/graphql'
import { format, isValid, parseISO } from 'date-fns'
import { utcToZonedTime, formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'

export type DateTimeType = 'DATETIME' | 'DATE' | 'UTC'

export type DateTimeFormatVariant =
  | 'MMMM d, yyyy hh:mm'
  | 'MMMM d, yyyy'
  | 'MM/dd/yyyy'
  | 'MMMM d, yyyy | hh:mm a'
  | 'yyyy-MM-dd'
  | 'HH:mm'

export type DateTimeFormatMapping = Record<DateTimeFormatVariant, string>

export const DateTimeFormat: DateTimeFormatMapping = {
  'MMMM d, yyyy hh:mm': 'MMMM d, yyyy hh:mm',
  'MMMM d, yyyy': 'MMMM d, yyyy',
  'MM/dd/yyyy': 'MM/dd/yyyy',
  'MMMM d, yyyy | hh:mm a': 'MMMM d, yyyy | hh:mm a',
  'yyyy-MM-dd': 'yyyy-MM-dd',
  'HH:mm': 'HH:mm',
}

export const DateFormatter = (
  dateTimeValue: Date | string,
  formatString: string = DateTimeFormat['MMMM d, yyyy'],
  dateTimetype: DateTimeType = 'DATE',
): string | undefined => {
  const parsedDateTime =
    typeof dateTimeValue === 'string' ? parseISO(dateTimeValue) : dateTimeValue

  if (!isValid(parsedDateTime)) return undefined

  if (dateTimetype === 'DATETIME') {
    return format(parsedDateTime, formatString)
  }

  if (dateTimetype === 'UTC') {
    return format(parsedDateTime, 'P')
  }

  return format(parsedDateTime, formatString)
}

export const getPastDate = (
  daysAgo: number,
  returnFormat: 'dateObject' | 'dateTime' | 'UTC' = 'dateObject',
): string | Date | null => {
  if (daysAgo < 0) return null

  const dateTime = new Date()
  dateTime.setDate(dateTime.getDate() - daysAgo)
  dateTime.setHours(0, 0, 0, 0) // Set time to midnight

  const formatMap: Record<
    'dateObject' | 'dateTime' | 'UTC',
    () => string | Date
  > = {
    dateObject: () => dateTime,
    dateTime: () => format(dateTime, "yyyy-MM-dd'T'HH:mm:ss"),
    UTC: () => dateTime.toISOString(), // Return ISO string directly
  }

  return formatMap[returnFormat]?.() ?? null
}

// Convert HH:MM format to minutes for internal handling
export const convertToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':').map(Number)
  return hours * 60 + minutes
}

// Convert minutes to hours and in a HH:MM format
export const convertMinutesToHHMM = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`
}

export const isDateInThePast = (date: string): boolean =>
  new Date(date) < new Date()

const usTzMap: Record<string, string> = {
  PACIFIC_TIME: 'Pacific Time',
  MOUNTAIN_TIME: 'Mountain Time',
  CENTRAL_TIME: 'Central Time',
  EASTERN_TIME: 'Eastern Time',
  ALASKA_TIME: 'Alaska Time',
}

export const timeZoneMap: Record<string, string> = {
  PACIFIC_TIME: 'America/Los_Angeles',
  MOUNTAIN_TIME: 'America/Denver',
  CENTRAL_TIME: 'America/Chicago',
  EASTERN_TIME: 'America/New_York',
  ALASKA_TIME: 'America/Anchorage',
}

export type UsTimezonesType =
  | UsTimeZone.AlaskaTime
  | UsTimeZone.CentralTime
  | UsTimeZone.EasternTime
  | UsTimeZone.MountainTime
  | UsTimeZone.PacificTime

export const getTimezoneName = (location: string): string | null =>
  usTzMap[location] ?? null

export const formatUTCtoLocalTimezone = (
  utcDatetime: Date,
  formatString: string,
  userTimezone: UsTimeZone = UsTimeZone.CentralTime,
): string | undefined => {
  const usTz = timeZoneMap[userTimezone]
  return utcDatetime
    ? formatInTimeZone(utcDatetime, usTz, formatString)
    : undefined
}

export const convertToTZ = (
  date: Date,
  timeZone: UsTimezonesType,
): Date | undefined => {
  const UsTimeZone = timeZoneMap[timeZone]
  return date ? utcToZonedTime(date, UsTimeZone) : undefined
}

export const convertToUTC = (
  date: Date,
  timeZone: UsTimezonesType,
): Date | undefined => {
  const UsTimeZone = timeZoneMap[timeZone]
  return date ? zonedTimeToUtc(date, UsTimeZone) : undefined
}
