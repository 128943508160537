import { useState, useRef } from 'react'
import { enqueueSnackbar } from 'notistack'
import { NetworkStatus, useQuery } from '@apollo/client'
import {
  TaskChargeType,
  type TaskMutationResponse,
  type MutationError,
  TaskStatus,
  CursorDirection,
  LegalMatterTasksDocument,
  Permission,
  TaskListItemFragment,
  Task,
} from '@generatedTypes/graphql'
import { paginationLimits } from '@/config'
import type { IUpload } from '@interfaces/Upload'
import TaskListItem from '@components/organisms/TaskListItem'
import ConfirmationDialog, {
  type ConfirmationDialogProps,
} from '@components/molecules/ConfirmationDialog'
import ChargeforTaskInfo from '@components/molecules/ChargeforTaskInfo'
import AnimatedList from '@components/molecules/AnimatedList'
import DotMenu, { DotMenuAnchor } from '@components/atoms/DotMenu'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import TaskLoader from '@components/molecules/TaskLoader'
import useTasks from '@utils/useTasks'
import useCurrentUser from '@utils/useCurrentUser'
import TaskFilter from '@components/organisms/TaskFilters'
import { makeEmptyData } from '@utils/useApolloClient'
import { getPastDate } from '@utils/dateUtils'
import AclEntity from '@models/AclEntity'
import TaskForm, { SelectAssigneeProps } from '@components/molecules/TaskForm'
import UploadWidget from '@components/molecules/UploadWidget'

interface TaskFilters {
  daysSinceUpdated: number
  tasksLastUpdatedSince?: Date
  tasksCompletedSince?: Date
  tasksAssignedToIn?: string[]
  tasksAssignedToNotIn?: string[]
  tasksStatus?: TaskStatus
}

interface TaskModuleProps {
  legalMatterKey: string | undefined
  isReadOnly: boolean
  selectAssigneeOptions: SelectAssigneeProps[]
  handleDocumentDownload?: (documentPath: string) => void
  handleDocumentDelete?: (documentKey: string) => void
  handleDocumentUpload?: (props: {
    userKey: string
    legalMatterKey: string
    taskKey: string
    file: File
  }) => IUpload
}

interface TaskDialogConfigProps extends ConfirmationDialogProps {
  confirmationCheckBox?: boolean
}

interface TaskOutofPlanInfo {
  showInfo?: boolean
  taskKey: string
  taskName: string
  chargeType?: TaskChargeType
  chargeReason?: string
  chargeAmount?: number
}

type TasksWithChanges = Record<string, TasksModeErrorsWithChange>

interface TasksModeErrorsWithChange {
  serverError?: string[]
  chargeMode?: TaskChargeType
}

const dialogConfigInitialState: TaskDialogConfigProps = {
  title: <></>,
  content: <></>,
  actions: <></>,
  open: false,
}

const TaskOutofPlanInfoInitialState: TaskOutofPlanInfo = {
  showInfo: false,
  taskKey: '',
  taskName: '',
  chargeType: undefined,
  chargeAmount: 0,
  chargeReason: '',
}

interface TaskDotMenuAnchor extends DotMenuAnchor {
  task: TaskListItemFragment
}

const defaultPastDays = 7

const LawyerTaskListing = ({
  legalMatterKey,
  isReadOnly,
  selectAssigneeOptions,
  handleDocumentUpload,
  handleDocumentDownload,
  handleDocumentDelete,
}: TaskModuleProps): JSX.Element => {
  const { user, loading: userLoading } = useCurrentUser()
  const [noTasksMessage, setNoTasksMessage] = useState<string>(
    'There are no tasks assigned to you.',
  )
  const [filters, setFilters] = useState<TaskFilters>({
    daysSinceUpdated: defaultPastDays,
  })
  const [dialogConfig, setDialogConfig] = useState<TaskDialogConfigProps>(
    dialogConfigInitialState,
  )
  const [dotMenuAnchor, setDotMenuAnchor] = useState<TaskDotMenuAnchor | null>(
    null,
  )

  const [legalMatterTasksWithChanges, setLegalMatterTasksWithChanges] =
    useState<TasksWithChanges>({})

  const [trackedTimeError, setTrackedTimeError] = useState<string | undefined>()

  const [outOfPlanTaskInfoProps, setOutOfPlanTaskInfoProps] =
    useState<TaskOutofPlanInfo>({ ...TaskOutofPlanInfoInitialState })

  const taskInUpdateRef = useRef('')

  const { data, networkStatus, refetch, fetchMore } = useQuery(
    LegalMatterTasksDocument,
    {
      notifyOnNetworkStatusChange: true,
      skip: userLoading || legalMatterKey == null,
      variables: {
        legalMatterKey,
        tasksLimit: paginationLimits.tasks,
        tasksAssignedToIn: [user.key],
        tasksCursorDirection: CursorDirection.Next,
        tasksLastUpdatedSince: getPastDate(defaultPastDays, 'UTC'),
      },
    },
  )

  const {
    deleteTask,
    updateTaskCharges,
    updateTaskStatus,
    updateTaskTrackedTime,
    updateTaskDetails,
    taskLoading,
  } = useTasks(legalMatterKey)

  const legalMatter = data?.legalMatters?.edges[0]?.node
  const tasks = legalMatter?.tasks?.edges?.map((edge) => edge.node) ?? []
  const pageInfo = legalMatter?.tasks?.pageInfo ?? makeEmptyData().pageInfo

  const [tasksInEditMode, setTasksInEditMode] = useState<Record<string, Task>>(
    {},
  )
  const onRemoveTaskInEditMode = (key: string): void => {
    const { [key]: _, ...filtered } = tasksInEditMode
    setTasksInEditMode(filtered)
  }

  const cancelPlanMode = (taskKey: string): void => {
    const { [taskKey]: _, ...filtered } = legalMatterTasksWithChanges
    setLegalMatterTasksWithChanges(filtered)
  }

  const onClickDone = (key, status): void => {
    void updateTaskStatus({
      variables: {
        taskKey: key,
        status,
      },
    })
  }

  const handleTaskChargeMode =
    (task, chargeMode?: TaskChargeType) => (key, data) => {
      taskInUpdateRef.current = key
      const amount = parseFloat(data.ChargeAmount ?? 0)
      void updateTaskCharges({
        variables: {
          taskKey: key,
          chargeType: chargeMode,
          chargeReason: data.ChargeReason,
          chargeAmount: amount,
        },
        onCompleted: (data) => {
          if (data.taskUpdateCharging.success) {
            const mutatedTask = data.taskUpdateCharging as TaskMutationResponse
            cancelPlanMode(mutatedTask.task.key)
            setOutOfPlanTaskInfoProps({
              ...outOfPlanTaskInfoProps,
              chargeAmount: mutatedTask?.task?.chargeAmount ?? 0,
              chargeReason: mutatedTask?.task?.chargeReason ?? '',
            })
          } else {
            const errors = data?.taskUpdateCharging as MutationError
            setLegalMatterTasksWithChanges({
              [taskInUpdateRef.current]: {
                serverError: [
                  errors.userErrors.length > 0
                    ? errors.userErrors[0]?.fieldErrors[0]?.errors[0]?.message
                    : errors.message,
                ],
              },
            })
          }
        },
        onError: () => {
          enqueueSnackbar(
            'There was an error updating the task, try again in a few seconds',
            {
              variant: 'error',
            },
          )
        },
      })
      setOutOfPlanTaskInfoProps({
        ...outOfPlanTaskInfoProps,
        showInfo: true,
        taskName: task?.name ?? '',
        chargeType: task?.chargeType ?? undefined,
        chargeAmount: task?.chargeAmount ?? 0,
        chargeReason: task?.chargeReason ?? '',
      })
    }

  const tasksDotMenuOptions = [
    {
      action: 'markAsNotDone',
      label: 'Mark as not done',
      icon: <EditIcon />,
      requiredPermission: Permission.TasksUpdateStatus,
      isVisible: (task) =>
        task !== null && task.status === TaskStatus.Completed,
    },
    {
      action: 'deleteTask',
      label: 'Delete Task',
      icon: <DeleteIcon />,
      requiredPermission: Permission.TasksDelete,
      isVisible: (task) => task != null,
    },
    {
      action: 'editTask',
      label: 'Edit Task',
      icon: <EditIcon />,
      requiredPermission: Permission.TasksDelete,
      isVisible: (task) => task != null,
    },
    {
      action: 'markTaskChargeOutofPlan',
      label: 'Mark as Out-of-Plan With Fee',
      icon: <ErrorOutlineIcon />,
      requiredPermission: Permission.TasksUpdateChargeStatus,
      isVisible: (task) =>
        task.status !== TaskStatus.Completed &&
        (task?.chargeType === null ||
          task?.chargeType === TaskChargeType.InPlanFlat),
    },
    {
      action: 'markTaskChargeInPlanFlat',
      label: 'Mark as In-Plan With Fee',
      icon: <ErrorOutlineIcon />,
      requiredPermission: Permission.TasksUpdateChargeStatus,
      isVisible: (task) =>
        task.status !== TaskStatus.Completed &&
        (task?.chargeType === null ||
          task?.chargeType !== TaskChargeType.InPlanFlat),
    },
    {
      action: 'markTaskWithinPlan',
      label: 'Mark within plan',
      icon: <AssignmentTurnedInOutlinedIcon />,
      requiredPermission: Permission.TasksUpdateChargeStatus,
      isVisible: (task) =>
        task.status !== TaskStatus.Completed &&
        (task?.chargeType !== null ||
          task?.chargeType === TaskChargeType.InPlanFlat ||
          task?.chargeType === TaskChargeType.OutOfPlan),
    },
  ]

  const handleTaskDotMenuClick = (
    event: React.MouseEvent,
    task: TaskListItemFragment,
  ): void => {
    const taskAcl = new AclEntity(task.acl)
    setDotMenuAnchor({
      task: task,
      element: event.currentTarget,
      menuItems: tasksDotMenuOptions.map((menuOpt) => ({
        ...menuOpt,
        visible: menuOpt.isVisible(task),
        disabled: !taskAcl.allows(
          user.aclIdentities,
          menuOpt.requiredPermission,
        ),
      })),
    })
  }

  const handleDialogActionConfirm = (action: string): void => {
    switch (action) {
      case 'markAsNotDone':
        if (dotMenuAnchor == null) return
        void updateTaskStatus({
          variables: {
            taskKey: dotMenuAnchor.task.key,
            status: TaskStatus.InProgress,
          },
          onCompleted: () => {
            setDialogConfig(dialogConfigInitialState)
          },
          onError: () => {
            enqueueSnackbar(
              'There was an error while updating the task, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
      case 'deleteTask':
        if (dotMenuAnchor == null) return
        void deleteTask({
          variables: {
            taskKey: dotMenuAnchor.task.key,
            removeDocuments: dialogConfig.confirmationCheckBox ?? false,
          },
          onCompleted: (data) => {
            setDialogConfig(dialogConfigInitialState)
            if (data.taskDelete.success) {
              enqueueSnackbar('Task deleted successfully', {
                variant: 'success',
              })
            } else {
              enqueueSnackbar(
                `There was an error while deleting the task: ${data.taskDelete.message}, try again in a few seconds`,
                {
                  variant: 'error',
                },
              )
            }
          },
          onError: () => {
            setDialogConfig(dialogConfigInitialState)
            enqueueSnackbar('There was a problem in deleting the task', {
              variant: 'error',
            })
          },
        })
        break
      case 'markTaskWithinPlan':
        if (dotMenuAnchor == null) return
        void updateTaskCharges({
          variables: {
            taskKey: dotMenuAnchor.task.key,
            chargeType: undefined,
          },
          onCompleted: (data) => {
            if (!data.taskUpdateCharging.success) {
              const errors = data?.taskUpdateCharging as MutationError
              setLegalMatterTasksWithChanges({
                [taskInUpdateRef.current]: {
                  serverError: [
                    errors.userErrors.length > 0
                      ? errors.userErrors[0]?.fieldErrors[0]?.errors[0]?.message
                      : errors.message,
                  ],
                },
              })
            }
            setDialogConfig(dialogConfigInitialState)
          },
          onError: () => {
            enqueueSnackbar(
              'There was an error while updating the task, try again in a few seconds',
              {
                variant: 'error',
              },
            )
          },
        })
        break
    }
  }

  const onClickDotMenuOption = (action: string): void => {
    switch (action) {
      case 'markAsNotDone':
        setDialogConfig((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Are you sure you want to proceed ?
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography variant="h4" gutterBottom>
                You are about to change the status of the task:{' '}
                {dotMenuAnchor?.task.name}, to the status of Task in progress
              </Typography>
            </>
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  setDialogConfig(dialogConfigInitialState)
                }}
                autoFocus
                variant="contained"
                color="error"
                data-testid="confirm-dialog-btn-cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDialogActionConfirm(action)
                }}
                autoFocus
                variant="contained"
                color="primary"
                data-testid="confirm-dialog-btn-accept"
              >
                Confirm
              </Button>
            </>
          ),
        }))
        break
      case 'deleteTask':
        setDialogConfig((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Are you sure you want to proceed ?
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography variant="h4" gutterBottom>
                You are about to delete the task: "{dotMenuAnchor?.task.name}".{' '}
                {/* If you want to delete the
                attached files with the task as well, please check the checkbox
                below */}
              </Typography>
              {dialogConfig.errorMessage !== undefined && (
                <Alert variant="outlined" severity="error">
                  {dialogConfig.errorMessage}
                </Alert>
              )}
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={dialogConfig?.confirmationCheckBox}
                    onChange={(e) => {
                      setDialogConfig((prevState) => ({
                        ...prevState,
                        confirmationCheckBox: e.target.checked,
                      }))
                    }}
                  />
                }
                label="Delete all attached files with the task"
              /> */}
            </>
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  setDialogConfig(dialogConfigInitialState)
                }}
                autoFocus
                variant="contained"
                color="error"
                data-testid="confirm-dialog-btn-cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDialogActionConfirm(action)
                }}
                autoFocus
                variant="contained"
                color="primary"
                data-testid="confirm-dialog-btn-accept"
              >
                Confirm
              </Button>
            </>
          ),
        }))
        break
      case 'editTask':
        if (dotMenuAnchor == null) return
        setTasksInEditMode({
          ...tasksInEditMode,
          [dotMenuAnchor?.task.key]: dotMenuAnchor?.task,
        })
        break
      case 'markTaskWithinPlan':
        setDialogConfig((prevState) => ({
          ...prevState,
          open: true,
          title: (
            <>
              <Typography variant="h1" gutterBottom>
                Task: {dotMenuAnchor?.task?.name ?? ''}
              </Typography>
            </>
          ),
          content: (
            <>
              <Typography>
                Please confirm if you'd like to mark this task within plan at no
                additional cost.
              </Typography>
            </>
          ),
          actions: (
            <>
              <Button
                onClick={() => {
                  setDialogConfig(dialogConfigInitialState)
                }}
                autoFocus
                variant="contained"
                color="error"
                data-testid="confirm-dialog-btn-cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDialogActionConfirm(action)
                }}
                autoFocus
                variant="contained"
                color="primary"
                data-testid="confirm-dialog-btn-accept"
              >
                Confirm
              </Button>
            </>
          ),
        }))
        break
      case 'markTaskChargeOutofPlan':
        setLegalMatterTasksWithChanges({
          ...legalMatterTasksWithChanges,
          [dotMenuAnchor?.task?.key]: { chargeMode: TaskChargeType.OutOfPlan },
        })
        break
      case 'markTaskChargeInPlanFlat':
        setLegalMatterTasksWithChanges({
          ...legalMatterTasksWithChanges,
          [dotMenuAnchor?.task?.key]: { chargeMode: TaskChargeType.InPlanFlat },
        })
        break
      default:
    }
  }

  const handleChangeModeInfoClick = (key): void => {
    const task = tasks.find((task) => task.key === key)
    setDialogConfig((prevState) => ({
      ...prevState,
      open: true,
      fullWidth: true,
      content: (
        <ChargeforTaskInfo
          taskName={task?.name ?? ''}
          chargeType={task?.chargeType !== null ? task?.chargeType : undefined}
          chargeReason={task?.chargeReason ?? ''}
          chargeAmount={task?.chargeAmount ?? 0}
        />
      ),
    }))
  }

  return (
    <>
      <TaskFilter
        daysSinceUpdated={filters.daysSinceUpdated}
        tabs={{
          'my-tasks': 'My Tasks',
          'client-tasks': 'Client Tasks',
          'other-attorney-tasks': 'Other Tasks',
        }}
        onChangeFilters={(newFilters) => {
          const targetDate = getPastDate(newFilters.daysSinceUpdated, 'UTC')
          const taskFilters: TaskFilters = {
            ...filters,
            tasksAssignedToIn: undefined,
            tasksAssignedToNotIn: undefined,
            daysSinceUpdated: newFilters.daysSinceUpdated,
            tasksLastUpdatedSince:
              targetDate instanceof Date ? targetDate : undefined,
          }

          switch (newFilters.context) {
            case 'my-tasks':
              if (user?.key != null) {
                taskFilters.tasksAssignedToIn = [user.key]
              }
              setNoTasksMessage('There are no tasks assigned to you.')
              break
            case 'client-tasks':
              if (legalMatter?.subscriber?.key != null) {
                taskFilters.tasksAssignedToIn = [legalMatter?.subscriber.key]
              }
              setNoTasksMessage('There are no tasks assigned to the client.')
              break
            default:
              taskFilters.tasksAssignedToNotIn = [
                user?.key,
                legalMatter?.subscriber?.key,
              ]
              setNoTasksMessage(
                'There are no tasks assigned to other attorneys.',
              )
              break
          }

          setFilters({ ...taskFilters })
          const { daysSinceUpdated, ...queryParams } = taskFilters
          refetch({ ...queryParams })
        }}
      />
      <Box data-testid="lawyer-task-list">
        <TaskLoader
          loading={
            userLoading ||
            [NetworkStatus.loading, NetworkStatus.setVariables].includes(
              networkStatus,
            )
          }
        >
          <AnimatedList animateItemKey={undefined}>
            {tasks.length > 0 ? (
              tasks.map((task) => {
                const taskAcl = new AclEntity(task.acl)
                return (
                  <TaskListItem
                    key={task.key}
                    user={user}
                    chargeType={
                      legalMatterTasksWithChanges[task.key]?.chargeMode
                    }
                    isUpdatingOutOfPlan={taskLoading}
                    isUpdating={taskLoading}
                    serverError={
                      legalMatterTasksWithChanges[task.key]?.serverError
                    }
                    task={{
                      ...task,
                      trackedMinutes:
                        task.assignedToRef != legalMatter?.subscriber?.key
                          ? task.trackedMinutes
                          : undefined,
                    }}
                    buttonText="Mark as Done"
                    onClickDone={
                      !isReadOnly &&
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksUpdateStatus,
                      )
                        ? onClickDone
                        : undefined
                    }
                    onClickMarkOutOfPlanButton={
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksUpdateChargeStatus,
                      )
                        ? handleTaskChargeMode(task, TaskChargeType.OutOfPlan)
                        : undefined
                    }
                    onClickMarkInPlanFlatButton={
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksUpdateChargeStatus,
                      )
                        ? handleTaskChargeMode(task, TaskChargeType.InPlanFlat)
                        : undefined
                    }
                    onClickCancelPlanButton={(key) => {
                      cancelPlanMode(key)
                    }}
                    onPlanInfoClick={handleChangeModeInfoClick}
                    attachedFiles={task.documents}
                    onFileClick={handleDocumentDownload}
                    onRemoveSelectedFile={
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksRemoveDocuments,
                      )
                        ? handleDocumentDelete
                        : undefined
                    }
                    fileUploader={
                      handleDocumentUpload &&
                      !isReadOnly &&
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksUploadDocuments,
                      ) ? (
                        <UploadWidget
                          uploadHandler={handleDocumentUpload}
                          handlerArgs={{
                            userKey: user.key,
                            legalMatterKey,
                            taskKey: task.key,
                          }}
                        />
                      ) : undefined
                    }
                    onClickMenu={
                      !isReadOnly
                        ? (event) => {
                            handleTaskDotMenuClick(event, task)
                          }
                        : undefined
                    }
                    trackedTimeError={trackedTimeError}
                    onChangeTrackedTime={
                      !isReadOnly &&
                      taskAcl.allows(
                        user.aclIdentities,
                        Permission.TasksUpdateTrackedTime,
                      )
                        ? (taskKey, trackedMinutes) => {
                            void updateTaskTrackedTime({
                              variables: {
                                taskKey,
                                trackedMinutes,
                              },
                              onCompleted: (data) => {
                                if (data.taskTrackedTimeUpdate.success) {
                                  setTrackedTimeError(undefined)
                                  enqueueSnackbar(
                                    'Task tracked time updated successfully',
                                    {
                                      variant: 'success',
                                    },
                                  )
                                } else {
                                  const errors =
                                    data?.taskTrackedTimeUpdate as MutationError
                                  setTrackedTimeError(errors[0]?.message)
                                }
                              },
                              onError: (error) => {
                                setTrackedTimeError(error?.message)
                              },
                            })
                          }
                        : undefined
                    }
                    inEditMode={task.key in tasksInEditMode}
                    editForm={
                      <TaskForm
                        taskName={task.name}
                        taskDescription={task.description}
                        textButton="Update"
                        selectAssigneeProps={selectAssigneeOptions}
                        assignedTo={task.assignedToRef}
                        isSubmitting={taskLoading}
                        onClickButton={(data) => {
                          void updateTaskDetails({
                            variables: {
                              taskKey: task.key,
                              name: data.name,
                              description: data.description,
                              assignedToKey: data.assignedTo,
                            },
                            onCompleted: (data) => {
                              if (data.taskUpdateDetails.success) {
                                enqueueSnackbar(
                                  'Task was updated successfully',
                                  {
                                    variant: 'success',
                                  },
                                )
                              }
                              onRemoveTaskInEditMode(task.key)
                              refetch()
                            },
                            onError: (error) => {
                              enqueueSnackbar(
                                'There was an error while updating the task, try again in a few seconds',
                                {
                                  variant: 'error',
                                },
                              )
                            },
                          })
                        }}
                        onClickCancelButton={() => {
                          onRemoveTaskInEditMode(task.key)
                        }}
                      />
                    }
                  />
                )
              })
            ) : (
              <Box sx={{ mt: 2 }}>
                <Alert severity="info" icon={false}>
                  <Typography variant="h5" gutterBottom>
                    {noTasksMessage}
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    You can create a task by clicking the "Add Task" button
                    above. You can use tasks to keep track of time spent working
                    on this legal matter or mark tasks as "out of plan" to be
                    billed separately.
                  </Typography>
                </Alert>
              </Box>
            )}
          </AnimatedList>
          {pageInfo.hasNextPage != null && pageInfo.endCursor != null ? (
            <Box mb={2} display="flex" justifyContent="flex-end">
              <Button
                loading={networkStatus === NetworkStatus.fetchMore}
                disableElevation
                variant="contained"
                color="primary"
                role="button"
                aria-label="Load More"
                onClick={() => {
                  if (pageInfo.endCursor != null) {
                    void fetchMore({
                      variables: {
                        tasksCursorDirection: CursorDirection.Next,
                        tasksCursor: pageInfo.endCursor,
                      },
                    })
                  }
                }}
                disabled={pageInfo.hasNextPage === false}
                data-testid="loading-button"
              >
                Load More
              </Button>
            </Box>
          ) : null}
        </TaskLoader>
      </Box>
      <DotMenu
        anchor={dotMenuAnchor}
        open={Boolean(dotMenuAnchor)}
        onItemSelected={(action) => {
          setDotMenuAnchor(null)
          onClickDotMenuOption(action)
        }}
        onClose={() => {
          setDotMenuAnchor(null)
        }}
      />
      <ConfirmationDialog
        open={dialogConfig.open}
        onClose={() => {
          setDialogConfig(dialogConfigInitialState)
        }}
        title={dialogConfig.title}
        content={dialogConfig.content}
        actions={dialogConfig.actions}
        errorMessage={dialogConfig?.errorMessage}
      />
    </>
  )
}

export default LawyerTaskListing
