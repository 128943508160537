import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { JsonFormType, type MutationError } from '@generatedTypes/graphql'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import IntakeFormStep from '@components/forms/IntakeFormStep'
import { type IntakeMutationProps } from '@interfaces/JsonSchemaForm'
import config from '@/config'
import { routes } from '@/routes'
import { UserProfileContext } from '@view/providers/UserProfileProvider'
import useSubscriberUser from '@utils/useSubscriberUser'

const SubscriberRegistration = (): JSX.Element => {
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useContext(UserProfileContext)
  const { createSubscriberUser, subscriberUserLoading } = useSubscriberUser()
  const [errors, setErrors] = useState<MutationError | undefined>(undefined)
  const [submissionSucceeded, setSubmissionSucceeded] = useState<boolean>(false)

  const onSubmit = ({ formData }: IntakeMutationProps): void => {
    void createSubscriberUser({
      variables: {
        data: formData,
      },
      onCompleted: (data) => {
        if (data?.subscriberCreate?.__typename === 'MutationError') {
          setErrors(data.subscriberCreate as MutationError)
          enqueueSnackbar('Error submitting form', { variant: 'error' })
        } else {
          enqueueSnackbar('Form submitted successfully', { variant: 'success' })
          setSubmissionSucceeded(true)
          navigate(routes.checkoutManageProducts.path)
        }
      },
    })
  }

  return (
    <>
      <Alert severity="info" icon={false} sx={{ mb: 3 }}>
        <Typography mb={2} data-testid="signup-restricted-state-notice">
          If your state does not appear in the dropdown menu below, LegalFix is
          not yet available in your state. Please contact us if you have any
          questions or want to be notified when LegalFix becomes available in
          your state.
        </Typography>
        {
          <Button
            component={Link}
            variant="contained"
            href={`mailto:${config.legalPlansContactEmail}`}
            data-testid="signup-contact-button"
          >
            Contact LegalFix
          </Button>
        }
      </Alert>
      <IntakeFormStep
        formType={JsonFormType.UserSubscription}
        onSubmit={onSubmit}
        submissionErrors={errors}
        submissionSuccess={submissionSucceeded}
        submitting={subscriberUserLoading}
        presetData={{
          userInfo: {
            email: user.profile.email,
          },
        }}
        presetUiSchema={{
          userInfo: {
            email: {
              'ui:readonly': true,
            },
          },
        }}
      />
    </>
  )
}

export default SubscriberRegistration
