import { type Permission, type IdentityKind } from '@generatedTypes/graphql'
import type AclIdentity from './AclIdentity'
import AclRule, { AclRuleArgs } from './AclRule'
import { encodeKey } from '@utils/KeyFormatter'

interface AclEntityArgs {
  aclRules: AclRuleArgs[]
}

export default class AclEntity {
  private readonly _rules: AclRule[]

  constructor(acl: AclEntityArgs | null | undefined) {
    this._rules =
      acl?.aclRules != null
        ? acl.aclRules.map((acl) => AclRule.fromData(acl))
        : []
  }

  allows(identities: AclIdentity[], permission: Permission): boolean {
    return identities.some((identity) =>
      this._rules.some((rule) => rule.allows(identity, permission)),
    )
  }

  getAclKeysByKind(kindFilter: IdentityKind): string[] {
    const kindIds = this._rules
      .map((aclItem) => aclItem.identity.uid)
      .filter((id): id is string => id !== null && id !== undefined)

    let encodedKeys
    switch (kindFilter) {
      case 'user':
        encodedKeys = kindIds.map((id) => encodeKey('firmUsers', id))
        break
      case 'group':
        encodedKeys = kindIds.map((id) => encodeKey('groups', id))
        break
      default:
        encodedKeys = []
        break
    }
    return encodedKeys
  }
}
