import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid2'
import Skeleton from '@mui/material/Skeleton'
import ItemBoundingBox from '@components/atoms/ItemBoundingBox'
import ThreeColumnLayout from '@components/templates/ThreeColumnLayout'
import styles from '@components/molecules/LoadingSkeleton.module.css'
import TwoColumnLayout from '@components/templates/TwoColumnLayout'

interface LegalMatterListLoadingProps {
  numberOfSkeletons?: number | undefined
}

export const Loader = ({
  visible,
  children,
}: {
  visible: boolean
  children: React.ReactNode
}): JSX.Element | null => (visible ? <Box>{children}</Box> : null)

export const LegalMatterListLoading = ({
  numberOfSkeletons,
}: LegalMatterListLoadingProps): JSX.Element => (
  <Box>
    {[...Array(numberOfSkeletons)].map((val, idx) => (
      <Card className={styles.card} key={`skeleton-${idx}`}>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid size={{ xs: 6, md: 4 }}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
          <Grid size={{ xs: 12, md: 3 }}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
        </Grid>
        <hr />
        <Box>
          <Skeleton variant="text" animation="wave" width={300} />
        </Box>
        <Grid container spacing={2}>
          <Grid size={{ xs: 6, md: 2 }}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
          <Grid size={{ xs: 6, md: 2 }}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={'space-between'}>
          <Grid size={{ xs: 6, md: 3 }}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
          <Grid size={{ xs: 6, md: 2 }}>
            <Skeleton variant="text" animation="wave" />
          </Grid>
        </Grid>
      </Card>
    ))}
  </Box>
)

export const SidebarSkeleton = (): JSX.Element => (
  <>
    <Skeleton
      variant="rectangular"
      animation="wave"
      height={45}
      sx={{ mb: 2 }}
    />
    <Skeleton variant="text" animation="wave" width={150} />
    <Skeleton variant="text" animation="wave" />
    <Skeleton variant="text" animation="wave" width={150} />
    <Skeleton variant="text" animation="wave" height={30} width={80} />
    <hr />
    <Skeleton variant="text" animation="wave" height={40} width="60%" />
    <Skeleton variant="text" animation="wave" width="70%" />
    <Skeleton variant="text" animation="wave" width="70%" sx={{ mb: 2 }} />
    <hr />
    <Skeleton
      variant="text"
      animation="wave"
      height={40}
      width="60%"
      sx={{ mt: 1 }}
    />
    <Skeleton variant="text" animation="wave" width="70%" />
    <Skeleton variant="text" animation="wave" width="70%" />
    <Skeleton variant="text" animation="wave" width="70%" />
    <Skeleton
      variant="text"
      animation="wave"
      height={40}
      width="60%"
      sx={{ mt: 2 }}
    />
    <Skeleton variant="text" animation="wave" width="70%" />
    <Skeleton variant="text" animation="wave" width="70%" />
    <Skeleton variant="text" animation="wave" width="70%" />
  </>
)

export const TaskSkeleton = (): JSX.Element => (
  <ItemBoundingBox variant="outlineBox">
    <Skeleton variant="text" animation="wave" width={300} />
    <hr />
    <Grid container justifyContent={'space-between'}>
      <Grid size={{ xs: 6, md: 6 }}>
        <Skeleton variant="text" animation="wave" />
        <Skeleton variant="text" animation="wave" />
      </Grid>
      <Grid size={{ xs: 6, md: 2 }}>
        <Skeleton variant="text" animation="wave" />
      </Grid>
    </Grid>
  </ItemBoundingBox>
)

export const NotesSkeleton = (): JSX.Element => (
  <>
    <Skeleton variant="rectangular" animation="wave" height={150} />
    <Skeleton
      variant="rectangular"
      animation="wave"
      height={20}
      width={50}
      sx={{ mt: 2 }}
    />
    <Skeleton variant="text" animation="wave" width={50} />
    <Skeleton variant="text" animation="wave" />
  </>
)

export const LegalMatterLoading = (): JSX.Element => (
  <ThreeColumnLayout>
    <Box>
      <SidebarSkeleton />
    </Box>
    <Box>
      <TaskSkeleton />
      <TaskSkeleton />
      <TaskSkeleton />
    </Box>
    <Box>
      <NotesSkeleton />
    </Box>
  </ThreeColumnLayout>
)

export const SubscriberLegalMatterLoading = (): JSX.Element => (
  <TwoColumnLayout>
    <Box>
      <SidebarSkeleton />
    </Box>
    <Box>
      <TaskSkeleton />
      <TaskSkeleton />
      <TaskSkeleton />
    </Box>
  </TwoColumnLayout>
)

export const JsonFormLoading = (): JSX.Element => (
  <Box>
    <ItemBoundingBox variant="outlineBox">
      <Grid container>
        <Grid size={{ xs: 10 }}>
          <Skeleton variant="text" animation="wave" height={50} />
          <hr />
        </Grid>
        <Grid size={{ xs: 8 }}>
          <Skeleton
            variant="text"
            animation="wave"
            height={50}
            sx={{ margin: 0, padding: 0 }}
          />
          <Skeleton variant="text" animation="wave" height={50} />
          <Skeleton variant="text" animation="wave" height={50} />
        </Grid>
        <Grid size={{ xs: 8 }} mt={2}>
          <Skeleton variant="rectangular" animation="wave" height={150} />
        </Grid>
        <Grid container mt={4} spacing={1}>
          <Grid mt={4}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={35}
              width={100}
            />
          </Grid>
          <Grid mt={4}>
            <Skeleton
              variant="rectangular"
              animation="wave"
              height={35}
              width={100}
            />
          </Grid>
        </Grid>
      </Grid>
    </ItemBoundingBox>
  </Box>
)

export const LegalMatterNoteSkeleton = (): JSX.Element => (
  <Box my={3}>
    <Grid container>
      <Grid size={{ xs: 10 }}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          height={35}
          width={100}
        />
      </Grid>
    </Grid>
  </Box>
)

export const CheckoutPageSkeleton = (): JSX.Element => (
  <>
    {[...Array(5)].map((val, idx) => (
      <Box key={idx} mb={2}>
        <Skeleton animation="wave" variant="text" width="20%" />
        <Skeleton animation="wave" variant="text" width="10%" />
        <Skeleton animation="wave" variant="text" width="40%" />
      </Box>
    ))}
  </>
)
