import { useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { NetworkStatus } from '@apollo/client'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { isDateInThePast } from '@utils/dateUtils'
import { useFirmInvite } from '@utils/useFirmInvite'
import FirmUser from '@models/FirmUser'
import SubscriberUser from '@models/SubscriberUser'
import { UserProfileContext } from '@view/providers/UserProfileProvider'
import { LegalMatterListLoading } from '@components/molecules/LoadingSkeletons'

const FirmInvite = (): JSX.Element => {
  const { user } = useContext(UserProfileContext)

  const userType =
    user instanceof FirmUser
      ? 'an Attorney'
      : user instanceof SubscriberUser
        ? 'a Subscriber'
        : ''

  const navigate = useNavigate()
  const { inviteKey } = useParams()
  const { data, networkStatus } = useFirmInvite({
    variables: {
      inviteRef: inviteKey,
    },
  })

  if (networkStatus === NetworkStatus.loading) {
    return <LegalMatterListLoading numberOfSkeletons={1} />
  }

  if (data?.firmsUserInvite == null) {
    return (
      <Alert severity="error" icon={false} sx={{ mb: 3 }}>
        <Typography mb={2} data-testid="signup-restricted-invite-missing">
          Sorry, the invite could not be found. Contact LegalFix team with any
          questions.
        </Typography>
      </Alert>
    )
  }

  if (user instanceof FirmUser || user instanceof SubscriberUser) {
    return (
      <Alert severity="info" icon={false} sx={{ mb: 3 }}>
        <Typography mb={2} data-testid="invite-message-non-firm-user">
          You are already registered as {userType}.
        </Typography>
        <Link
          onClick={() => {
            navigate('/')
          }}
        >
          Go to My Legal Matters.
        </Link>
      </Alert>
    )
  }

  if (isDateInThePast(data?.firmsUserInvite.expiresAt)) {
    return (
      <Alert severity="error" icon={false} sx={{ mb: 3 }}>
        <Typography mb={2} data-testid="signup-restricted-state-notice">
          Sorry, the invitation expired. Contact LegalFix team with any
          questions.
        </Typography>
      </Alert>
    )
  }

  return (
    <Box>
      <Typography variant="h1" sx={{ mt: 8 }}>
        Invite to join LegalFix!
      </Typography>
      <Typography variant="body1" sx={{ mt: 8, mb: 6 }} gutterBottom>
        You've been invited to join {data?.firmsUserInvite.firm.name} in
        LegalFix.
      </Typography>
      <Typography variant="body1" gutterBottom>
        {data?.firmsUserInvite.email} have been invited to join{' '}
        {data?.firmsUserInvite.firm.name} team to start fielding request for
        clients seeking legal services.
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          navigate(`/firms/sign-up/firm_user/${inviteKey!}`)
        }}
        sx={[
          {
            backgroundColor: 'primary.main',
            color: 'white',
            fontWeight: 700,
            border: 2,
            borderRadius: '2px',
            lineHeight: '22px',
            textAlign: 'center',
            padding: '10px 25px',
            marginTop: '10px',
          },
          {
            '&:hover': {
              backgroundColor: 'primary.main',
              color: 'white',
              border: 2,
            },
          },
        ]}
      >
        Join LegalFix
      </Button>
      <Typography variant="subtitle1" sx={{ mt: 4 }}>
        We are excited for you to join LegalFix
      </Typography>
      <Typography variant="subtitle1" sx={{ fontStyle: 'italic' }}>
        Neal - CEO of LegalFix
      </Typography>
    </Box>
  )
}

export default FirmInvite
