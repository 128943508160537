import React from 'react'
import Grid from '@mui/material/Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { MobileDateTimePicker } from '@mui/x-date-pickers'
import ScheduleIcon from '@mui/icons-material/Schedule'
import styles from '@components/molecules/EventDateTime.module.css'
import {
  UsTimezonesType,
  convertToTZ,
  convertToUTC,
  timeZoneMap,
} from '@/utils/dateUtils'
import { UsTimeZone } from '@/@types/generated/graphql'

interface Event {
  startTime?: Date
  endTime?: Date
  timeZone?: UsTimezonesType
  onChangeStartTime?: (value: Date) => void
  onChangeEndTime?: (value: Date) => void
}

const EventDateTime = ({
  startTime,
  endTime,
  timeZone = UsTimeZone.CentralTime,
  onChangeStartTime,
  onChangeEndTime,
}: Event): JSX.Element => {
  const readOnly =
    onChangeStartTime === undefined && onChangeEndTime === undefined
  const UsTimezone = timeZoneMap[timeZone]

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid
        container
        data-testid="datetimepicker-box"
        sx={{ marginBottom: '8px' }}
      >
        {startTime !== undefined && (
          <Grid
            className={styles.timePicker}
            data-testid="datetimepicker-starttime-picker"
          >
            <ScheduleIcon aria-label="Schedule" />
            <MobileDateTimePicker
              value={convertToTZ(startTime, timeZone)}
              defaultValue={convertToTZ(startTime, timeZone)}
              minutesStep={5}
              readOnly={readOnly}
              onChange={(value) => {
                const utcDate = convertToUTC(value!, timeZone)
                if (utcDate) {
                  onChangeStartTime?.(utcDate)
                }
              }}
              slotProps={{
                textField: ({ inputRef, inputProps }) => (
                  <input
                    id="startTime"
                    ref={inputRef}
                    {...inputProps}
                    className={styles.timeInput}
                    role="input"
                    aria-label="Start Time Input"
                  />
                ),
              }}
              sx={{
                '& .MuiInputBase-root': {
                  padding: '0',
                  border: 'none',
                },
                '& input': {
                  padding: '0',
                  border: 'none',
                  outline: 'none',
                  cursor: 'pointer',
                  maxWidth: '10rem',
                },
                '& fieldset': {
                  border: 'none',
                },
              }}
              timezone={UsTimezone}
            />
          </Grid>
        )}
        {endTime !== undefined && (
          <>
            <Grid mx={1}> - </Grid>
            <Grid
              className={styles.timePicker}
              data-testid="datetimepicker-endtime-picker"
            >
              <ScheduleIcon aria-label="Schedule" />
              <MobileDateTimePicker
                value={convertToTZ(endTime, timeZone)}
                minutesStep={5}
                readOnly={readOnly}
                onChange={(value) => {
                  const utcDate = convertToUTC(value!, timeZone)
                  if (utcDate) {
                    onChangeEndTime?.(utcDate)
                  }
                }}
                slotProps={{
                  textField: ({ inputRef, inputProps }) => (
                    <input
                      id="endTime"
                      ref={inputRef}
                      {...inputProps}
                      className={styles.timeInput}
                      role="input"
                      aria-label="End Time Input"
                    />
                  ),
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    padding: '0',
                    border: 'none',
                  },
                  '& input': {
                    padding: '0',
                    border: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    maxWidth: '10rem',
                  },
                  '& fieldset': {
                    border: 'none',
                  },
                }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </LocalizationProvider>
  )
}

export default EventDateTime
