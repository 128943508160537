import { useContext } from 'react'
import FirmUser from '@models/FirmUser'
import { UserProfileContext } from '@view/providers/UserProfileProvider'
import LawyerLegalMatter from '@components/pages/LawyerLegalMatter'
import SubscriberLegalMatter from '@components/pages/SubscriberLegalMatter'

const LegalMatterDetail = (): JSX.Element | null => {
  const { user } = useContext(UserProfileContext)

  if (user instanceof FirmUser) {
    return <LawyerLegalMatter />
  }

  return <SubscriberLegalMatter />
}

export default LegalMatterDetail
