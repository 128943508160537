export type LabelVariant =
  | 'LEGAL_MATTER_NUMBER'
  | 'LEGAL_MATTER_TYPE'
  | 'LEGAL_MATTER_DETAILS'
  | 'LEGAL_MATTER_INFO_TAB'
  | 'LEGAL_MATTER_ASSIGNMENT_TAB'
  | 'LEGAL_MATTER_INTAKE_TAB'
  | 'DATE_CREATED'
  | 'DOCUMENTS_UPLOADED'
  | 'REQUEST_ADDITIONAL_DOCUMENTS'
  | 'SIDEBAR_UPLOAD_DOCUMENTS'
  | 'CLIENT_NAME'
  | 'PHONE_LABEL'
  | 'EMAIL_LABEL'
  | 'SUBSCRIPTION'
  | 'NO_COSULTATIONS_AVAILABLE'
  | 'FIREBASE_UPLOAD_ERROR_MESSAGE'

export type STATUS =
  | 'ASSIGNED'
  | 'ASSIGNING'
  | 'CANCELED'
  | 'CLOSED'
  | 'UNASSIGNED'

export type LabelMapping = Record<LabelVariant, string>

export type StatusMapping = Record<STATUS, string>

export type referOutLabelsType = {
  mainNotice: string
  documentsNotice: string
}

export const labelSlugs: LabelMapping = {
  LEGAL_MATTER_NUMBER: 'Legal Matter',
  LEGAL_MATTER_TYPE: 'Type',
  LEGAL_MATTER_DETAILS: 'Details',
  DATE_CREATED: 'Date created',
  DOCUMENTS_UPLOADED: 'Documents Uploaded',
  REQUEST_ADDITIONAL_DOCUMENTS: 'Request Additional Documents',
  SIDEBAR_UPLOAD_DOCUMENTS: 'Attach Files',
  CLIENT_NAME: 'Client Name',
  PHONE_LABEL: 'Phone',
  EMAIL_LABEL: 'Email',
  SUBSCRIPTION: 'Legal Insurance Plan',
  NO_COSULTATIONS_AVAILABLE: 'You have no consultations scheduled at this time',
  FIREBASE_UPLOAD_ERROR_MESSAGE:
    'There was a problem in uploading the file. Please contact support if you keep facing the issue',
  LEGAL_MATTER_INFO_TAB: 'Legal Matter Information',
  LEGAL_MATTER_ASSIGNMENT_TAB: 'Assignment',
  LEGAL_MATTER_INTAKE_TAB: 'Intake form data',
}

export const statusColors: StatusMapping = {
  ASSIGNED: 'success',
  ASSIGNING: 'info',
  CANCELED: 'error',
  CLOSED: 'error',
  UNASSIGNED: 'error',
}

export const referOutLabels: referOutLabelsType = {
  mainNotice:
    'By sending this matter to the LegalFix network it will become available to other law firms in the network  who may choose to accept the matter.',
  documentsNotice:
    'Any documents saved to this matter will be accessible by other law firms in the network who accept the matter.',
}
