/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, type ChangeEvent, useCallback } from 'react'
import { debounce } from 'lodash'
import log from 'loglevel'
import Box from '@mui/material/Box'
import ButtonGroup from '@mui/material/ButtonGroup'
import Typography from '@mui/material/Typography'
import styles from '@components/molecules/TimeInput.module.css'
import { convertMinutesToHHMM, convertToMinutes } from '@utils/dateUtils'

interface Props {
  minutes: number
  label?: string
  trackedTimeError?: string | undefined
  onChange?: (minutes: number) => void
}

const TimeInput = ({
  label,
  minutes,
  trackedTimeError,
  onChange,
}: Props): JSX.Element => {
  const [stringTime, setStringTime] = useState<string>(
    convertMinutesToHHMM(minutes),
  )
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    trackedTimeError,
  )
  const isReadOnly = onChange == null

  // Validate value from input to formatted string
  const validateInputValue = (hours: string): string => {
    const paddedHours = hours.padStart(2, '0')

    if (/^([0-9][0-9]?[0-9]):([0-5][0-9])$/.test(hours)) {
      setErrorMessage(undefined)
      if (/^\d{0,3}:\d{2}$/.test(hours)) {
        return hours
      }

      return `${paddedHours}:00`
    }
    setErrorMessage('Format should be HH:mm')
    return convertMinutesToHHMM(minutes)
  }

  // Debounce the update of debouncedValue
  const debouncedUpdate = useCallback(
    debounce((value: number) => {
      if (onChange !== undefined) {
        log.warn('sending to onChange', value)
        onChange(value)
      }
    }, 2000),
    [],
  )

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setStringTime(e.target.value)
    const inputValue = validateInputValue(e.target.value)

    debouncedUpdate(convertToMinutes(inputValue))
  }

  const handleIncrement = (): void => {
    const newTime = convertToMinutes(stringTime) + 15
    setStringTime(convertMinutesToHHMM(newTime))

    debouncedUpdate(newTime)
  }

  const handleDecrement = (): void => {
    const newTime = convertToMinutes(stringTime) - 15
    if (newTime >= 0) {
      setStringTime(convertMinutesToHHMM(newTime))
      debouncedUpdate(newTime)
    } else {
      setStringTime('00:00') // Reset to zero if subtracting below zero
    }
  }

  return (
    <Box aria-label="Time tracking widget">
      <ButtonGroup size="small" className={styles.buttonWrapper}>
        {label != null ? (
          <Typography variant="subtitle2" className={styles.label}>
            {label}
          </Typography>
        ) : null}
        {!isReadOnly ? (
          <button
            onClick={handleDecrement}
            disabled={convertToMinutes(stringTime) === 0}
            data-testid="timeinput-decrease-btn"
            aria-label="Decrease 15 minutes"
            className={`${styles.timeButton} ${styles.leftTimeButton}`}
          >
            -
          </button>
        ) : null}
        <input
          type="text"
          disabled={isReadOnly}
          onChange={handleChange}
          value={stringTime}
          onBlur={(e: ChangeEvent<HTMLInputElement>): void => {
            setStringTime(validateInputValue(e.target.value))
          }}
          placeholder="HH:MM"
          aria-label="Tracked time input"
          data-testid="timeinput-input"
          className={styles.inputBox}
        />
        {minutes != null && minutes >= 0 && !isReadOnly ? (
          <button
            onClick={handleIncrement}
            data-testid="timeinput-increase-btn"
            aria-label="Increase 15 minutes"
            className={`${styles.timeButton} ${styles.rightTimeButton}`}
          >
            +
          </button>
        ) : null}
      </ButtonGroup>
      {errorMessage !== undefined && (
        <Typography
          variant="h6"
          color="error"
          data-testid="timeinput-error-message"
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}

export default TimeInput
