import type { AclEntity as GqlAclEntity } from '@generatedTypes/graphql'
import AclEntity from './AclEntity'

export default class BaseEntity {
  public readonly acl: AclEntity

  constructor(acl: GqlAclEntity | null | undefined) {
    this.acl = new AclEntity(acl)
  }
}
