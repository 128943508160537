import { type MutationHookOptions, useMutation } from '@apollo/client'
import { gql } from '@generatedTypes/gql'
import {
  type CreateCheckoutSessionMutation,
  type CreateCheckoutSessionMutationVariables,
  type SubscriptionProductsAddMutation,
  type SubscriptionProductsAddMutationVariables,
} from '@generatedTypes/graphql'

const createCheckoutSessionMutation = gql(/* GraphQL */ `
  mutation createCheckoutSession(
    $successUrl: String!
    $cancelUrl: String!
    $stripePrices: [String!]!
  ) {
    checkoutSessionCreate(
      successUrl: $successUrl
      cancelUrl: $cancelUrl
      stripePrices: $stripePrices
    ) {
      ... on SessionUrlResponse {
        success
        sessionUrl
      }
      ... on MutationError {
        message
      }
    }
  }
`)

const addSubscriptionProductsMutation = gql(/* GraphQL */ `
  mutation SubscriptionProductsAdd($stripePrices: [String!]!) {
    subscriptionProductsAdd(stripePrices: $stripePrices) {
      ... on SubscriptionProductAddMutationResponse {
        code
        message
        success
      }
      ... on MutationError {
        code
        message
        success
        userErrors {
          code
          fieldErrors {
            errors {
              message
            }
          }
        }
      }
    }
  }
`)

export const useCreateCheckoutSession = (
  baseOptions?: MutationHookOptions<
    CreateCheckoutSessionMutation,
    CreateCheckoutSessionMutationVariables
  >,
) => {
  return useMutation<
    CreateCheckoutSessionMutation,
    CreateCheckoutSessionMutationVariables
  >(createCheckoutSessionMutation, baseOptions)
}

export const useExtraProductCheckout = (
  baseOptions?: MutationHookOptions<
    SubscriptionProductsAddMutation,
    SubscriptionProductsAddMutationVariables
  >,
) => {
  return useMutation<
    SubscriptionProductsAddMutation,
    SubscriptionProductsAddMutationVariables
  >(addSubscriptionProductsMutation, baseOptions)
}
