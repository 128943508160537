import React from 'react'
import { isBefore } from 'date-fns'
import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import {
  LegalMatterSidebarDetailFragment,
  LegalMatterStatus,
  Permission,
} from '@generatedTypes/graphql'
import NameValueLabel from '@components/atoms/NameValueLabel'
import SidebarDocuments from '@components/molecules/SidebarDocuments'
import { friendlyUUID } from '@utils/StringFormatter'
import EventDateTime from '@components/molecules/EventDateTime'
import { AddToCalendarButton } from 'add-to-calendar-button-react'
import { DateFormatter, formatUTCtoLocalTimezone } from '@utils/dateUtils'
import { type StatusLabels } from '@utils/StatusMapper'
import User from '@models/User'
import model from '@/models'
import Access from '@components/molecules/Access'
import { Stack } from '@mui/material'

export interface LegalMatterSidebarProps {
  /** Data fragment mapping legal matter partial from GraphQL query **/
  legalMatterFragment: LegalMatterSidebarDetailFragment
  legalMatterStatusLabels?: StatusLabels
  /** Callback function listening on clicks over document request button **/
  onClickDocumentRequestButton?: () => void
  /** Text of the action button displayed in the side bar **/
  textDocumentsRequestButton?: string
  children?: React.ReactNode
  maxDocumentsToDisplay?: number
  onDocumentClick?: (documentPath: string) => void
  onDocumentDelete?: (documentKey: string) => void
  /** Deprecated ?  **/
  eventReadOnly?: boolean
  fileUploader?: JSX.Element
  user: User
  renderDocuments?: boolean
}

const toDate = (date: string | Date): Date => {
  return typeof date === 'string' ? new Date(date) : date
}

/**
 * Contextual sidebar providing details about the parties involved in a legal matter
 */
export const LegalMatterSidebar = ({
  legalMatterFragment,
  legalMatterStatusLabels,
  onClickDocumentRequestButton,
  onDocumentClick,
  onDocumentDelete,
  user,
  children,
  renderDocuments = true,
  ...props
}: LegalMatterSidebarProps): JSX.Element => {
  const legalMatterModel = new model.LegalMatter(legalMatterFragment)
  const calendarEvents =
    legalMatterFragment?.calendarEvents?.edges?.map((edge) => edge.node) ?? []
  const documents =
    legalMatterFragment?.documents?.edges?.map((edge) => edge.node) ?? []

  const nextEvent =
    calendarEvents.length > 0
      ? calendarEvents
          .filter((event) => isBefore(new Date(), toDate(event.endTime))) // Excluding past events
          .reduce((acc, current) => {
            // Compare endTime for the closest upcoming event
            return isBefore(toDate(acc.endTime), toDate(current.endTime))
              ? acc
              : current
          }, [] as any)
      : null

  const localizedStartTime = formatUTCtoLocalTimezone(
    nextEvent?.startTime,
    'yyyy-MM-dd HH:mm:ss zzz',
    user.profile.timeZone,
  )
  const nextEventStartDate = formatUTCtoLocalTimezone(
    nextEvent?.startTime,
    'yyy-MM-dd',
    user.profile.timeZone,
  )
  const nextEventStartTime = formatUTCtoLocalTimezone(
    nextEvent?.startTime,
    'HH:mm',
    user.profile.timeZone,
  )

  const localizedEndTime = formatUTCtoLocalTimezone(
    nextEvent?.endTime,
    'yyyy-MM-dd HH:mm:ss zzz',
    user.profile.timeZone,
  )
  const nextEventEndDate = formatUTCtoLocalTimezone(
    nextEvent?.endTime,
    'yyy-MM-dd',
    user.profile.timeZone,
  )
  const nextEventEndTime = formatUTCtoLocalTimezone(
    nextEvent?.endTime,
    'HH:mm',
    user.profile.timeZone,
  )

  return (
    <Box>
      <Box sx={{ px: 0.5 }}>
        <NameValueLabel
          data-chromatic="ignore"
          typographyProps={{
            gutterBottom: true,
            variant: 'h2',
            component: 'h2',
            fontFamily: 'monospace',
          }}
          name="Legal Matter ID"
          value={friendlyUUID(legalMatterFragment.key)}
          chipValue={true}
        />
        <NameValueLabel
          name="Created"
          value={DateFormatter(legalMatterFragment.createdTimestamp)}
          typographyProps={{ variant: 'subtitle2', gutterBottom: true }}
          data-testid="sidebar-createdOn"
        />
        <Access
          requires={legalMatterModel.get('status') === LegalMatterStatus.Closed}
        >
          <NameValueLabel
            name="Closed"
            value={DateFormatter(legalMatterFragment.closedTimestamp)}
            typographyProps={{ variant: 'subtitle2', gutterBottom: true }}
            data-testid="sidebar-closed-on"
          />
        </Access>
        <Access
          requires={
            legalMatterModel.get('status') === LegalMatterStatus.Canceled
          }
        >
          <NameValueLabel
            name="Cancelled"
            value={DateFormatter(legalMatterFragment.canceledTimestamp)}
            typographyProps={{ variant: 'subtitle2', gutterBottom: true }}
            data-testid="sidebar-cancelled-on"
          />
        </Access>
        <NameValueLabel
          name="Legal Service(s) requested"
          value={legalMatterFragment.kind?.name ?? 'N/A'}
          typographyProps={{
            gutterBottom: true,
            variant: 'subtitle2',
          }}
        ></NameValueLabel>
        <NameValueLabel
          name="Jurisdiction"
          value={legalMatterFragment.subscriber?.location?.state?.name ?? 'N/A'}
          typographyProps={{ gutterBottom: true, variant: 'subtitle2' }}
        />
        <Chip
          label={
            legalMatterStatusLabels?.[legalMatterFragment.status] ??
            legalMatterStatusLabels?.[LegalMatterStatus.Unassigned]
          }
          data-testid="sidebar-legal-matter-status"
          style={{ textTransform: 'none' }}
          color="info"
          size="small"
          sx={{ mt: 1 }}
        />
      </Box>
      <Divider sx={{ mt: 1 }} />
      {!isBefore(new Date(nextEvent?.endTime), new Date()) &&
        localizedStartTime !== undefined &&
        localizedEndTime !== undefined &&
        nextEvent?.name !== undefined && (
          <Access requires={legalMatterModel.canRejectShare()}>
            <Alert
              severity="info"
              icon={false}
              data-testid="next-consultation"
              action={
                <Tooltip title="Info for consultation" placement="left">
                  <IconButton>
                    <InfoOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
            >
              <Typography gutterBottom variant="subtitle1">
                Next Consultation
              </Typography>
              <Typography
                gutterBottom
                variant="body1"
                data-testid="next-consultation-name"
              >
                {nextEvent?.name}
              </Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography gutterBottom variant="body1">
                  Starts
                </Typography>
                <EventDateTime
                  startTime={new Date(localizedStartTime)}
                  timeZone={user.profile.timeZone}
                />
              </Stack>
              <Stack
                direction="row"
                sx={{ mb: 2 }}
                spacing={1}
                alignItems="center"
              >
                <Typography gutterBottom variant="body1">
                  Ends
                </Typography>
                <EventDateTime
                  startTime={new Date(localizedEndTime)}
                  timeZone={user.profile.timeZone}
                />
              </Stack>
              <AddToCalendarButton
                name={nextEvent?.name}
                startDate={nextEventStartDate}
                startTime={nextEventStartTime}
                endDate={nextEventEndDate}
                endTime={nextEventEndTime}
                data-testid="add-ToCalendar"
                options={[
                  'Google',
                  'Apple',
                  'iCal',
                  'Microsoft365',
                  'MicrosoftTeams',
                  'Outlook.com',
                  'Yahoo',
                ]}
                timeZone="currentBrowser"
                buttonStyle="round"
                listStyle="modal"
                size={'4'}
                styleLight="--btn-shadow: none; --btn-shadow-hover: none;  --btn-shadow-active: none;"
              ></AddToCalendarButton>
            </Alert>
            <Divider />
          </Access>
        )}
      {renderDocuments && (
        <>
          <SidebarDocuments
            textDocumentsRequestButton={props.textDocumentsRequestButton}
            documents={documents}
            onClickDocumentRequestButton={onClickDocumentRequestButton}
            onClickToDocument={onDocumentClick}
            onDocumentDelete={onDocumentDelete}
            fileUploader={
              legalMatterModel.acl.allows(
                user.aclIdentities,
                Permission.DocumentsCreate,
              )
                ? props.fileUploader
                : undefined
            }
            user={user}
          />
          <Divider sx={{ my: 2 }} />
        </>
      )}
      {children}
    </Box>
  )
}

export default LegalMatterSidebar
