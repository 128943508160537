import { type Price, ProductInterval } from '@generatedTypes/graphql'
import orderBy from 'lodash/orderBy'
import { Buffer } from 'buffer'

export const resolveSubscriberName = (
  firstName?: string,
  lastName?: string,
): string | undefined => {
  const subscriberName = `${firstName !== undefined ? firstName + ' ' : ''}${
    lastName ?? ''
  }`
  return subscriberName.length > 0 ? subscriberName : undefined
}

export const friendlyUUID = (uuid: string): string => {
  const finalPart = uuid.slice(-6)
  return (finalPart.slice(0, 3) + '-' + finalPart.slice(3)).toLocaleLowerCase()
}

export const decodeBase64 = (data): string => {
  return Buffer.from(data, 'base64').toString('ascii')
}

export const encodeToBase64 = (data: string): string => {
  return Buffer.from(data, 'ascii').toString('base64')
}

export const formatCurrency = (
  value: number | bigint,
  locale = 'en-US',
  currency = 'USD',
): string => {
  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value)
}

export const printPriceLabel = (
  unitAmount: number,
  interval?: ProductInterval | null,
): string => {
  const periodLabel =
    interval === ProductInterval.Month
      ? 'monthly'
      : interval === ProductInterval.Year
        ? 'annually'
        : ''
  return `Pay ${
    unitAmount != null ? formatCurrency(unitAmount / 100) : '-'
  } ${periodLabel}`
}

export const priceCaption = (
  prices: Pick<Price, 'unitAmount' | 'recurringInterval'>[],
): string => {
  const captions = orderBy(prices, ['recurringInterval'], ['asc']).map(
    (price) => {
      return printPriceLabel(price.unitAmount, price.recurringInterval)
    },
  )
  return captions.length > 1 ? captions.join(' | ') : captions[0]
}
