/* eslint-disable */
import { extendTheme } from '@mui/material/styles'
import LinkBehavior from '../components/atoms/LinkBehavior'

// If something is added in the themeConstants remember to also add the object added to the extendTheme
const themeConstants = {
	palette: {
		common: {
			black: '#666666',
			white: '#FFFFFF',
		},
		primary: {
			main: '#1b69ba',
			light: '#3193FC',
			dark: '#204576',
			contrastText: '#e5f6fd',
		},
		secondary: {
			main: '#1e55b5',
			light: '#EAF0F7',
			dark: '#036CDB',
			contrastText: '#1e55b5',
		},
		error: {
			main: '#d32f2f',
			light: '#ef5350',
			dark: '#B43A31',
			contrastText: '#fff',
		},
		warning: {
			main: '#ed6c02',
			light: '#ff9800',
			dark: '#e65100',
			contrastText: '#fff',
		},
		info: {
			main: '#0288d1',
			light: '#03a9f4',
			dark: '#01579b',
			contrastText: '#fff',
		},
		success: {
			main: '#2e7d32',
			light: '#4caf50',
			dark: '#467821',
			contrastText: '#fff',
		},
		grey: {
			50: '#fafafa',
			100: '#f5f5f5',
			200: '#eeeeee',
			300: '#e0e0e0',
			400: '#bdbdbd',
			500: '#9e9e9e',
			600: '#757575',
			700: '#616161',
			800: '#424242',
			900: '#212121',
			A100: '#d5d5d5',
			A200: '#aaaaaa',
			A400: '#303030',
			A700: '#666666',
		},
		text: {
			primary: '#3169B4',
			secondary: '#333333',
			disabled: 'rgba(0,0,0,0.38)',
		},
		background: {
			default: '#ffffff',
		},
	},
	typography: {
		htmlFontSize: 16,
		fontFamily: 'Open Sans',
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		color: '#3169B4',
		h1: {
			fontFamily: 'Open Sans',
			fontWeight: 700,
			fontSize: '1.5rem',
			lineHeight: '2rem',
			wordBreak: 'break-all',
		},
		h2: {
			color: '#666666',
			fontSize: '18px',
			fontFamily: 'Open Sans',
			fontWeight: '700',
			lineHeight: '24px',
		},
		h3: {
			color: '#333333',
			fontSize: '1rem',
			fontFamily: 'Open Sans',
			fontWeight: '700',
			lineHeight: '1.5rem',
		},
		h4: {
			color: '#333333',
			fontSize: '1rem',
			fontFamily: 'Open Sans',
			fontWeight: '600',
			lineHeight: '1.5rem',
		},
		h5: {
			color: '#333333',
			fontFamily: 'Open Sans',
			fontWeight: 700,
			fontSize: '1rem',
			lineHeight: '1.2',
			letterSpacing: '-0.01562em',
			'@media (min-width:600px)': {
				fontSize: '1.5rem',
			},
		},
		h6: {
			color: '#333333',
			fontFamily: 'Open Sans',
			fontWeight: 600,
			fontSize: '0.875rem',
			lineHeight: '1.25rem',
		},
		body1: {
			lineHeight: '1.2',
			color: '#666666',
		},
		subtitle1: {
			color: '#666666',
			fontSize: '1rem',
			fontFamily: 'Open Sans',
			fontWeight: '600',
			lineHeight: '1.5rem',
		},
		subtitle2: {
			color: '#666666',
			fontSize: '0.875rem',
			fontFamily: 'Open Sans',
			fontWeight: '400',
			lineHeight: '20px',
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1200,
			xl: 1920,
		},
	},
	themeSpacing: 8,
}

const theme = extendTheme({
	palette: {
		common: {
			black: themeConstants.palette.common.black,
			white: themeConstants.palette.common.white,
		},
		primary: {
			main: themeConstants.palette.primary.main,
			light: themeConstants.palette.primary.light,
			dark: themeConstants.palette.primary.dark,
			contrastText: themeConstants.palette.primary.contrastText,
		},
		secondary: {
			main: themeConstants.palette.secondary.main,
			light: themeConstants.palette.secondary.light,
			dark: themeConstants.palette.secondary.dark,
			contrastText: themeConstants.palette.secondary.contrastText,
		},
		error: {
			main: themeConstants.palette.error.main,
			light: themeConstants.palette.error.light,
			dark: themeConstants.palette.error.dark,
			contrastText: themeConstants.palette.error.contrastText,
		},
		warning: {
			main: themeConstants.palette.warning.main,
			light: themeConstants.palette.warning.light,
			dark: themeConstants.palette.warning.dark,
			contrastText: themeConstants.palette.warning.contrastText,
		},
		info: {
			main: themeConstants.palette.info.main,
			light: themeConstants.palette.info.light,
			dark: themeConstants.palette.info.dark,
			contrastText: themeConstants.palette.info.contrastText,
		},
		success: {
			main: themeConstants.palette.success.main,
			light: themeConstants.palette.success.light,
			dark: themeConstants.palette.success.dark,
			contrastText: themeConstants.palette.success.contrastText,
		},
		grey: {
			50: themeConstants.palette.grey[50],
			100: themeConstants.palette.grey[100],
			200: themeConstants.palette.grey[200],
			300: themeConstants.palette.grey[300],
			400: themeConstants.palette.grey[400],
			500: themeConstants.palette.grey[500],
			600: themeConstants.palette.grey[600],
			700: themeConstants.palette.grey[700],
			800: themeConstants.palette.grey[800],
			900: themeConstants.palette.grey[900],
			A100: themeConstants.palette.grey.A100,
			A200: themeConstants.palette.grey.A200,
			A400: themeConstants.palette.grey.A400,
			A700: themeConstants.palette.grey.A700,
		},
		text: {
			primary: themeConstants.palette.text.primary,
			secondary: themeConstants.palette.text.secondary,
			disabled: themeConstants.palette.text.disabled,
		},
		background: {
			default: themeConstants.palette.background.default,
		},
	},
	typography: {
		htmlFontSize: themeConstants.typography.htmlFontSize,
		fontFamily: themeConstants.typography.fontFamily,
		fontSize: themeConstants.typography.fontSize,
		fontWeightLight: themeConstants.typography.fontWeightLight,
		fontWeightRegular: themeConstants.typography.fontWeightRegular,
		fontWeightMedium: themeConstants.typography.fontWeightMedium,
		fontWeightBold: themeConstants.typography.fontWeightBold,
		h1: {
			fontFamily: themeConstants.typography.h1.fontFamily,
			fontWeight: themeConstants.typography.h1.fontWeight,
			fontSize: themeConstants.typography.h1.fontSize,
			lineHeight: themeConstants.typography.h1.lineHeight,
			letterSpacing: themeConstants.typography.h1.letterSpacing,
			wordBreak: themeConstants.typography.h1.wordBreak,
		},
		h2: {
			fontFamily: themeConstants.typography.h2.fontFamily,
			fontWeight: themeConstants.typography.h2.fontWeight,
			fontSize: themeConstants.typography.h2.fontSize,
			lineHeight: themeConstants.typography.h2.lineHeight,
		},
		h3: {
			color: themeConstants.typography.h3.color,
			fontSize: themeConstants.typography.h3.fontSize,
			fontFamily: themeConstants.typography.h3.fontFamily,
			fontWeight: themeConstants.typography.h3.fontWeight,
			lineHeight: themeConstants.typography.h3.lineHeight,
		},
		h4: {
			color: themeConstants.typography.h4.color,
			fontSize: themeConstants.typography.h4.fontSize,
			fontFamily: themeConstants.typography.h4.fontFamily,
			fontWeight: themeConstants.typography.h4.fontWeight,
			lineHeight: themeConstants.typography.h4.lineHeight,
		},
		h5: {
			color: themeConstants.typography.h5.color,
			fontFamily: themeConstants.typography.h5.fontFamily,
			fontWeight: themeConstants.typography.h5.fontWeight,
			fontSize: themeConstants.typography.h5.fontSize,
			lineHeight: themeConstants.typography.h5.lineHeight,
			letterSpacing: '-0.01562em',
			[`@media (max-width: 576px)`]: {
				padding: `calc(${themeConstants.themeSpacing}px * 0.5) 0px;`,
			},
			[`@media (max-width: 992px)`]: {
				fontSize: themeConstants.typography.fontSize,
			},
		},
		h6: {
			color: themeConstants.typography.h6.color,
			fontFamily: themeConstants.typography.h6.fontFamily,
			fontWeight: themeConstants.typography.h6.fontWeight,
			fontSize: themeConstants.typography.h6.fontSize,
			lineHeight: themeConstants.typography.h6.lineHeight,
		},
		body1: {
			fontSize: themeConstants.typography.fontSize,
		},
		subtitle1: {
			color: themeConstants.typography.subtitle1.color,
			fontSize: themeConstants.typography.subtitle1.fontSize,
			fontFamily: themeConstants.typography.subtitle1.fontFamily,
			fontWeight: themeConstants.typography.subtitle1.fontWeight,
			lineHeight: themeConstants.typography.subtitle1.lineHeight,
		},
		subtitle2: {
			color: themeConstants.typography.subtitle2.color,
			fontSize: themeConstants.typography.subtitle2.fontSize,
			fontFamily: themeConstants.typography.subtitle2.fontFamily,
			fontWeight: themeConstants.typography.subtitle2.fontWeight,
			lineHeight: themeConstants.typography.subtitle2.lineHeight,
		},
	},
	breakpoints: {
		values: {
			xs: themeConstants.breakpoints.values.xs,
			sm: themeConstants.breakpoints.values.sm,
			md: themeConstants.breakpoints.values.md,
			lg: themeConstants.breakpoints.values.lg,
			xl: themeConstants.breakpoints.values.xl,
		},
	},
	themeSpacing: themeConstants.themeSpacing,
	shape: {
		borderRadius: 2,
	},
	themeFontSizes: {
		h1: themeConstants.typography.h1.fontSize,
		h3: themeConstants.typography.h3.fontSize,
		h5: themeConstants.typography.h5.fontSize,
		p: themeConstants.typography.fontSize,
	},
	// MUI components overrides
	components: {
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
		},
		MuiButton: {
			variants: [
				{
					props: { variant: 'contained', color: 'primary' },
					style: {
						cursor: 'pointer',
						fontFamily: themeConstants.typography.fontFamily,
						fontWeight: '600',
						fontSize: themeConstants.typography.htmlFontSize,
						lineHeight: themeConstants.typography.h2.lineHeight,
						padding: `calc(${themeConstants.themeSpacing}px * 0.5) calc(${themeConstants.themeSpacing}px * 2)`,
						textTransform: 'inherit',
						background: themeConstants.palette.primary.main,
						'&:hover': {
							background: '#204576',
						},
					},
				},
				{
					props: { variant: 'contained', color: 'error' },
					style: {
						cursor: 'pointer',
						fontFamily: themeConstants.typography.fontFamily,
						fontWeight: '600',
						fontSize: themeConstants.typography.htmlFontSize,
						lineHeight: themeConstants.typography.h2.lineHeight,
						padding: `calc(${themeConstants.themeSpacing}px * 0.5) calc(${themeConstants.themeSpacing}px * 2)`,
						textTransform: 'inherit',
						'&:hover': {
							background: themeConstants.palette.error.dark,
						},
					},
				},
				{
					props: { variant: 'contained', color: 'success' },
					style: {
						cursor: 'pointer',
						fontFamily: themeConstants.typography.fontFamily,
						fontWeight: '600',
						fontSize: themeConstants.typography.htmlFontSize,
						lineHeight: themeConstants.typography.h2.lineHeight,
						padding: `calc(${themeConstants.themeSpacing}px * 0.5) calc(${themeConstants.themeSpacing}px * 2)`,
						textTransform: 'inherit',
						'&:hover': {
							background: themeConstants.palette.success.dark,
						},
					},
				},
				{
					props: { variant: 'outlined' },
					style: {
						cursor: 'pointer',
						fontFamily: themeConstants.typography.fontFamily,
						fontWeight: '600',
						fontSize: themeConstants.typography.htmlFontSize,
						lineHeight: themeConstants.typography.h2.lineHeight,
						padding: `calc(${themeConstants.themeSpacing}px * 0.5) calc(${themeConstants.themeSpacing}px * 2)`,
						textTransform: 'inherit',
						'&:hover': {
							background: 'transparent',
						},
					},
				},
				{
					props: { variant: 'outlined', size: 'small' },
					style: {
						cursor: 'pointer',
						fontFamily: themeConstants.typography.fontFamily,
						fontWeight: '600',
						fontSize: themeConstants.typography.h6.fontSize,
						lineHeight: themeConstants.typography.h2.lineHeight,
						padding: `calc(${themeConstants.themeSpacing}px * 0.5) calc(${themeConstants.themeSpacing}px * 1)`,
						textTransform: 'inherit',
						'&:hover': {
							background: 'transparent',
						},
					},
				},
				{
					props: { variant: 'outlined', color: 'secondary' },
					style: {
						borderColor: themeConstants.palette.common.white,
						color: themeConstants.palette.common.white,
						cursor: 'pointer',
						fontFamily: themeConstants.typography.fontFamily,
						fontWeight: '600',
						fontSize: themeConstants.typography.htmlFontSize,
						lineHeight: themeConstants.typography.h2.lineHeight,
						padding: `calc(${themeConstants.themeSpacing}px * 0.5) calc(${themeConstants.themeSpacing}px * 2)`,
						textTransform: 'inherit',
						'&:hover': {
							background: themeConstants.palette.common.white,
							color: themeConstants.palette.common.black,
						},
					},
				},
				{
					props: { variant: 'text' },
					style: {
						padding: '0',
					},
				},
			],
		},
		MuiLink: {
			defaultProps: {
				component: LinkBehavior,
			},
			styleOverrides: {
				root: {
					color: themeConstants.palette.text.primary,
					cursor: 'pointer',
					fontSize: '14px',
					fontFamily: 'Open Sans',
					fontWeight: '600',
					textTransform: 'inherit',
				},
			},
		},
		MuiRating: {
			styleOverrides: {
				root: {
					color: themeConstants.palette.grey.A700,
					fontSize: '1.5rem',
				},
			},
		},
		MuiSvgIcon: {
			variants: [
				{
					props: { color: 'success' },
					style: {
						color: themeConstants.palette.success.dark,
					},
				},
			],
			styleOverrides: {
				root: {
					color: themeConstants.palette.grey[700],
					width: `${themeConstants.themeSpacing * 2}px`,
					height: `${themeConstants.themeSpacing * 2}px`,
					marginRight: `${themeConstants.themeSpacing * 0.5}px`,
				},
			},
		},
		MuiCollapse: {
			styleOverrides: {
				entering: {
					backgroundColor: 'black',
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					color: themeConstants.palette.primary.contrastText
				},
				colorDefault: {
					color: themeConstants.palette.primary.main
				},
				colorWarning: {
					color: themeConstants.palette.warning.main
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					color: themeConstants.palette.primary.main
				},
			},
		},
		MuiSelect: {
			styleOverrides: {
				root: {
					color: themeConstants.palette.primary.main
				},
			},
		}
	},
})

export default theme
