import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import { StripeAccountDetailsFragment } from '@generatedTypes/graphql'

interface Props {
  accountFragment: StripeAccountDetailsFragment
}

const StripeAccountDetails = ({ accountFragment }: Props): JSX.Element => {
  return (
    <Grid container>
      <TableContainer>
        <Table>
          <TableRow>
            <TableCell>
              <Typography variant="h5">Charges enabled</Typography>
            </TableCell>
            <TableCell>
              {accountFragment.chargesEnabled ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CancelIcon />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h5">Payouts enabled</Typography>
            </TableCell>
            <TableCell>
              {accountFragment.payoutsEnabled ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CancelIcon />
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="h5">Capabilities</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Card payments</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">
                {accountFragment.capabilities.cardPayments === 'active' ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon />
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="h6">Transfers</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6">
                {accountFragment.capabilities.transfers === 'active' ? (
                  <CheckCircleIcon color="success" />
                ) : (
                  <CancelIcon />
                )}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              {' '}
              <Typography variant="h5">Details submitted</Typography>{' '}
            </TableCell>
            <TableCell>
              {accountFragment.detailsSubmitted ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CancelIcon />
              )}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Grid>
  )
}

export default StripeAccountDetails
