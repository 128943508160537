import React from 'react'
import { useQuery } from '@apollo/client'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import { FirmsDocument } from '@generatedTypes/graphql'
import { Grid, Typography } from '@mui/material'

interface FirmDropdownProps {
  onChange: (firmRef: string | null) => void
  firmRef: string | null
}

const FirmsDropdown: React.FC<FirmDropdownProps> = ({ onChange, firmRef }) => {
  const { data, loading, error } = useQuery(FirmsDocument)

  const handleFirmChange = (event: SelectChangeEvent<string | null>) => {
    const value = event.target.value as string
    onChange(value === 'all-firms' ? null : value)
  }

  if (loading) return <CircularProgress size={20} />
  if (error)
    return (
      <Grid display="flex" alignItems="center" mb={2}>
        <Alert sx={{ display: 'flex', alignItems: 'center' }} severity="error">
          Error fetching firms.
        </Alert>
      </Grid>
    )

  return (
    <Grid display="flex" alignItems="center">
      <Grid item>
        <Typography>Filter by Firm:</Typography>
      </Grid>
      <Grid item>
        <FormControl sx={{ marginLeft: '8px' }} variant="outlined">
          <InputLabel id="firm-select-label">Select Firm</InputLabel>
          <Select
            labelId="firm-select-label"
            value={firmRef ?? 'all-firms'}
            onChange={handleFirmChange}
            label="Select Firm"
            sx={{ minWidth: '200px' }}
          >
            <MenuItem value="all-firms">
              <em>All Firms</em>
            </MenuItem>
            {data?.firms?.map((firm: { key: string; name: string }) => (
              <MenuItem key={firm.key} value={firm.key}>
                {firm.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default FirmsDropdown
