import { type Error } from '@generatedTypes/graphql'
import { type JsonFormError } from '@interfaces/JsonSchemaForm'

export const transformFormJsonErrorToArray = (
  jsonError: JsonFormError | undefined,
): string[] => {
  const errors = jsonError?.userErrors
  const message = jsonError?.message
  return transformFormErrorsToArray(errors, message)
}

export const transformFormErrorsToArray = (
  errors?: Error[],
  message?: string,
): string[] => {
  return errors !== undefined && errors.length > 0
    ? errors?.map((e) => {
        const fieldErrors =
          e.fieldErrors !== undefined && e.fieldErrors.length > 1
            ? e.fieldErrors.slice(1)
            : e.fieldErrors
        const errorMessages =
          fieldErrors !== undefined
            ? fieldErrors.reduce((accum, item) => {
                accum += `${item.name}: ${item.errors?.reduce(
                  (errorAccum, errorItem) => {
                    errorAccum += errorItem.message
                    return errorAccum
                  },
                  '',
                )}, `
                return accum
              }, '')
            : 'There was an error in processing the request, '
        return errorMessages.substring(0, errorMessages.length - 2)
      })
    : message !== undefined
      ? [message]
      : []
}
