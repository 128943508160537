import { StripeSubscriptionStatus } from '@generatedTypes/graphql'

export interface SubscriptionsFilter {
  status?: StripeSubscriptionStatus | StripeSubscriptionStatus[]
}

interface SubscriptionsFilterStatus {
  label: string
  filter: SubscriptionsFilter
}

export type SubcsriptionsFilterStatusMap = Record<
  string,
  SubscriptionsFilterStatus
>

export const SubscriptionsStatusMap: SubcsriptionsFilterStatusMap = {
  All: { label: 'All', filter: { status: undefined } },
  Current: {
    label: 'Current',
    filter: {
      status: [
        StripeSubscriptionStatus.Active,
        StripeSubscriptionStatus.Incomplete,
        StripeSubscriptionStatus.Unpaid,
      ],
    },
  },
  Inactive: {
    label: 'Inactive',
    filter: {
      status: [
        StripeSubscriptionStatus.Canceled,
        StripeSubscriptionStatus.PastDue,
      ],
    },
  },
}
