import { IdentityKind } from '@generatedTypes/graphql'

export default class AclIdentity {
  private readonly _kind: IdentityKind
  private readonly _uid: string

  constructor(kind: IdentityKind, uid: string) {
    this._uid = uid
    this._kind = kind
  }

  get kind(): IdentityKind {
    return this._kind
  }

  get uid(): string {
    return this._uid
  }

  toString(): string {
    return `${this._kind as string}:${this._uid}`
  }

  static fromString(identity: string): AclIdentity {
    const [kind, uid] = identity.split(':')
    const _kind = kind as IdentityKind
    if (!Object.values(IdentityKind).includes(_kind)) {
      throw new Error('Invalid kind')
    }
    return new AclIdentity(_kind, uid)
  }

  equals(other: AclIdentity): boolean {
    return this._kind === other.kind && this._uid === other.uid
  }
}
