import { type SubmitHandler, useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid2'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ItemBoundingBox from '@components/atoms/ItemBoundingBox'

export interface serverError {
  key: string | null
  message: string | undefined
}

interface DataProps {
  noteKey: string | undefined
  noteText: string | undefined
}

type Props = DataProps & {
  textPlaceholder?: string
  textButton?: string
  textCancelButton?: string
  isSubmitting: boolean
  serverError?: serverError
  onHandleChange?: (event) => void
  onClickButton: (data: DataProps) => void
  onClickCancelButton: (value?: string) => void
}

const NoteEditForm: React.FC<Props> = ({
  noteKey,
  noteText,
  textPlaceholder = 'Enter new content for the note',
  textButton = 'Save',
  textCancelButton = 'Cancel',
  serverError,
  isSubmitting = false,
  onHandleChange,
  onClickButton,
  onClickCancelButton,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors: validationErrors },
  } = useForm<DataProps>()
  const fields = {
    noteKey: register('noteKey', { value: noteKey }),
    noteText: register('noteText', {
      value: noteText,
      required: { value: true, message: 'Note content is required' },
      pattern: {
        value: /\w+\b/i,
        message: 'Please enter a valid value',
      },
      maxLength: 1000,
    }),
  }

  const onSubmit: SubmitHandler<DataProps> = (data) => {
    let formValid = false

    if (noteKey !== undefined && noteText !== undefined) {
      formValid = true
    }
    if (formValid) {
      onClickButton(data)
    }
  }

  return (
    <form
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData)
      })}
    >
      <ItemBoundingBox variant="outlineBox">
        <Grid container justifyContent="space-between">
          <Grid size={{ xs: 12, sm: 12 }}>
            <TextField
              fullWidth
              id="edditNoteInput"
              type="text"
              {...fields.noteText}
              error={!(validationErrors?.noteText === undefined)}
              helperText={validationErrors?.noteText?.message}
              onChange={onHandleChange}
              placeholder={textPlaceholder}
              multiline
              rows={3}
              size="small"
              variant="standard"
              data-testid="edit-note-input"
              aria-label="Edit Note Input"
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt={2}
        >
          {serverError?.key === noteKey && (
            <Grid
              container
              direction={'row'}
              data-testid="create-event-server-error"
            >
              <Grid>
                <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                  {serverError?.message}
                </Typography>
              </Grid>
            </Grid>
          )}
          {textButton !== undefined && textCancelButton !== undefined ? (
            <Grid container justifyContent="space-evenly">
              <Grid marginX={0}>
                <Button
                  disableElevation
                  variant="text"
                  color="secondary"
                  data-testid="edit-note-cancel-button"
                  onClick={() => {
                    onClickCancelButton(noteKey)
                  }}
                  sx={{
                    textTransform: 'none',
                    fontSize: '14px',
                    padding: '4px 8px',
                  }}
                >
                  {textCancelButton}
                </Button>
              </Grid>
              <Grid marginX={0}>
                <Button
                  loading={isSubmitting}
                  disableElevation
                  variant="contained"
                  color="primary"
                  data-testid="edit-note-save-button"
                  type="submit"
                  sx={{ fontSize: '14px', padding: '4px 8px' }}
                >
                  {textButton}
                </Button>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </ItemBoundingBox>
    </form>
  )
}

export default NoteEditForm
