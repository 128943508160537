import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid2'
import Typography from '@mui/material/Typography'
import logo from '@view/assets/logo.webp'
import styles from '@components/pages/MaintenancePage.module.css'

const MaintenancePage = ({ message }): JSX.Element => {
  return (
    <Box className={styles.maintenanceBox}>
      <Grid container maxWidth="sm" className={styles.maintenanceContainer}>
        <Grid>
          <img
            className={styles.maintenanceImg}
            src={logo}
            alt="LegalFix"
            aria-label="Legalfix Logo"
          />
          <Typography variant="body1" className={styles.maintenanceText}>
            {message}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

export default MaintenancePage
