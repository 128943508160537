import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneIcon from '@mui/icons-material/Phone'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import SeparationGridBox from '@components/atoms/SeparationGridBox'
import { resolveSubscriberName } from '@utils/StringFormatter'
import NameValueLabel, {
  resolveNameValueLabel,
} from '@components/atoms/NameValueLabel'
import FormattedPhoneNumber from '@components/molecules/FormattedPhoneNumber'
import Stack from '@mui/material/Stack'

interface LawFirmProps {
  lawyerName?: string
  lawyerLastName?: string
  lawyerEmail?: string
  lawyerPhone?: string
  firmName?: string
  firmEmail?: string
  firmPhone?: string
}

const LawFirmContact = ({
  lawyerName,
  lawyerLastName,
  lawyerEmail,
  lawyerPhone,
  firmName,
}: LawFirmProps): JSX.Element => {
  return (
    <SeparationGridBox>
      <Grid item>
        <Typography gutterBottom variant="subtitle1">
          Legal Service Providers
        </Typography>
        <Button
          variant="text"
          size="small"
          startIcon={<ApartmentOutlinedIcon />}
          sx={{ textTransform: 'none' }}
        >
          <NameValueLabel
            value={firmName}
            typographyProps={{ gutterBottom: true, variant: 'h4' }}
          />
        </Button>
      </Grid>
      <Grid item>
        <Typography gutterBottom sx={{ mt: 2 }} variant="subtitle1">
          Contact
        </Typography>
        <Button
          variant="text"
          size="small"
          startIcon={<PersonOutlineIcon fontSize="small" />}
          sx={{ textTransform: 'none' }}
        >
          <NameValueLabel
            value={resolveSubscriberName(lawyerName, lawyerLastName)}
            typographyProps={{ gutterBottom: true, variant: 'h4' }}
          />
        </Button>
        <Stack alignItems="center" direction="row" gap={1}>
          <MailOutlineIcon fontSize="small" />
          <Typography variant="h4">
            {lawyerEmail !== undefined ? (
              <Link
                variant="overline"
                data-testid="document-uploaded"
                href={`mailto:${String(lawyerEmail)}`}
              >
                {lawyerEmail}
              </Link>
            ) : (
              resolveNameValueLabel(lawyerEmail)
            )}
          </Typography>
        </Stack>
        <FormattedPhoneNumber
          phoneNumber={lawyerPhone}
          icon={<PhoneIcon fontSize="small" />}
        />
      </Grid>
    </SeparationGridBox>
  )
}

export default LawFirmContact
