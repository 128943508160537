import React from 'react'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import EventDateTime from '@components/molecules/EventDateTime'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import NameValueLabel from '@components/atoms/NameValueLabel'
import {
  type CalendarEvent,
  type LegalMatterStatus,
} from '@generatedTypes/graphql'
import { UsTimezonesType } from '@/utils/dateUtils'

interface LegalMatterItemBodyProps {
  clientName?: string
  lawyerName?: string
  nextEvent?: CalendarEvent | null
  nextEventStartTime?: Date
  nextEventEndTime?: Date
  userTimeZone?: UsTimezonesType
  status?: LegalMatterStatus
  statusValue?: string
  legalMatterType?: string
  legalMatterSubtype?: string
  textButton?: string
  onClickButton?: () => void
  onClickViewDetails?: () => void
  onClickCalendar?: () => void
  onClickTime?: () => void
}

const LegalMatterItemBody = ({
  clientName,
  lawyerName,
  nextEvent,
  textButton,
  statusValue,
  legalMatterType,
  userTimeZone,
  legalMatterSubtype,
  onClickButton,
  onClickCalendar,
  onClickTime,
  onClickViewDetails,
}: LegalMatterItemBodyProps): JSX.Element => {
  return (
    <Grid container>
      <Grid item md={8}>
        {legalMatterSubtype !== undefined && (
          <Grid item data-testid="legal-matter-subtype" py={1}>
            <NameValueLabel
              value={legalMatterSubtype}
              typographyProps={{ variant: 'h6' }}
            />
          </Grid>
        )}
        {clientName !== undefined && (
          <Grid item data-testid="client-name" py={1}>
            <NameValueLabel
              name="Member"
              value={clientName}
              typographyProps={{ variant: 'h5' }}
            />
          </Grid>
        )}
        {nextEvent !== undefined && (
          <Grid item py={1}>
            <NameValueLabel
              value="Next scheduled event"
              typographyProps={{ variant: 'h6', gutterBottom: true }}
            />
            <EventDateTime
              startTime={nextEvent?.startTime}
              endTime={nextEvent?.endTime}
              timeZone={userTimeZone}
            />
          </Grid>
        )}
      </Grid>
      <Grid item md={4}>
        {textButton !== undefined ? (
          <Grid container item ml={1} pr={1} my={1} direction={'row-reverse'}>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              data-testid="assign-lawyer-review-request"
              onClick={onClickButton}
            >
              {textButton}
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid container>
        <Grid item md={8} py={1}>
          {statusValue !== undefined && (
            <Chip
              label={statusValue}
              data-testid="legal-matter-status"
              color="info"
              size="small"
            />
          )}
          {lawyerName !== undefined && (
            <Button
              variant="text"
              size="small"
              startIcon={<AccountCircleOutlinedIcon />}
              sx={{ ml: 2, textTransform: 'none' }}
            >
              <NameValueLabel
                value={lawyerName}
                typographyProps={{ variant: 'h3' }}
              />
            </Button>
          )}
        </Grid>
        {onClickViewDetails !== undefined ? (
          <Grid
            container
            item
            data-testid="box-footer"
            direction="row-reverse"
            md={4}
          >
            <Link
              variant="overline"
              data-testid="view-details"
              onClick={onClickViewDetails}
            >
              View Details
            </Link>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  )
}

export default LegalMatterItemBody
