import { Children, useRef, isValidElement } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import styles from '@components/molecules/AnimatedList.module.css'
import { type Scalars } from '@generatedTypes/graphql'

interface Props {
  animateItemKey: Scalars['Key']
  children: React.ReactNode
}

const AnimatedList = ({ animateItemKey, children }: Props): JSX.Element => {
  const lastItemRef = useRef(null)

  return (
    <TransitionGroup>
      {Children.map(children, (child) => {
        if (!isValidElement(child)) {
          return child
        }

        if (child.props !== undefined) {
          return (
            <CSSTransition
              key={child.key}
              timeout={1000}
              classNames={{ ...styles }}
              nodeRef={lastItemRef}
            >
              <div ref={child.key === animateItemKey ? lastItemRef : null}>
                {child}
              </div>
            </CSSTransition>
          )
        }
      })}
    </TransitionGroup>
  )
}

export default AnimatedList
