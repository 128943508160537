import React from 'react'
import styles from './ItemBoundingBox.module.css'
import Box from '@mui/material/Box'
import useTestId from '@utils/useTestId'

export type BoundingBoxVariants = 'outlineBox' | 'labelBox' | 'contentBox'

interface BoundingBoxProps {
  variant?: BoundingBoxVariants
  children: React.ReactNode
  ref?: any
  'data-testid'?: string
}

const ItemBoundingBox = ({
  variant,
  children,
  ...props
}: BoundingBoxProps): JSX.Element => {
  const testId = useTestId(props)
  switch (variant) {
    case 'labelBox':
      return (
        <Box p={1} className={styles.labelBox} alignItems="center" {...testId}>
          {children}
        </Box>
      )
    case 'outlineBox':
      return (
        <Box p={2} mb={3} className={styles.outlineBox} {...testId}>
          {children}
        </Box>
      )
    case 'contentBox':
      return (
        <Box p={1} mb={1} className={styles.contentBox} {...testId}>
          {children}
        </Box>
      )
    default:
      return (
        <Box p={2} mb={3} className={styles.outlineBox} {...testId}>
          {children}
        </Box>
      )
  }
}

export default ItemBoundingBox
